import React, { Component } from "react";
import { Col, Row,message,Card} from "antd"; 
import Widget from "components/Widget/index";
import axios from 'axiosConfig';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Line, LineChart} from "recharts";

import data from "./data";

const IconWithTextCard = ({cardColor, icon, title, subTitle, iconColor}) => {
  return (
    <Widget styleName={`gx-card-full gx-p-3 gx-bg-${cardColor} gx-text-white`}>
      <div className="gx-media gx-align-items-center gx-flex-nowrap">
        <div className="gx-mr-2 gx-mr-xxl-3">
          <i className={`icon icon-${icon} gx-fs-icon-lg`}/>
        </div>
        <div className="gx-media-body">
          <h1 className="gx-fs-xxl gx-font-weight-semi-bold gx-mb-1 gx-text-white">{title}</h1>
          <p className="gx-mb-0">{subTitle}</p>
        </div>
      </div>
    </Widget>
  );
};



const TinyBarChart = (props) => (

  <ResponsiveContainer width="100%" height={200}>
    <BarChart data={props.vehicleCategory}
              margin={{top: 10, right: 0, left: -15, bottom: 0}}>
      <XAxis dataKey="name"/>
      <YAxis/>
      <CartesianGrid strokeDasharray="3 3"/>
      <Tooltip/>
      
      <Bar dataKey="value" fill="#003366"/>
      
    </BarChart>
  </ResponsiveContainer>
);




const SimpleLineChart = (props) => (
  <ResponsiveContainer width="100%" height={200}>
    <LineChart data={props.details}
               margin={{top: 10, right: 0, left: -15, bottom: 0}}>
      <XAxis dataKey="name"/>
      <YAxis/>
      <CartesianGrid strokeDasharray="3 3"/>
      <Tooltip/>
      <Legend/>
      <Line type="monotone" dataKey="distance" stroke="#003366" activeDot={{r: 8}}/>
   { // <Line type="monotone" dataKey="engineHours" stroke="#FE9E15"/>
   }
    
    </LineChart>
  </ResponsiveContainer>
);

const SimpleLineChart2 = (props) => (
  <ResponsiveContainer width="100%" height={200}>
    <LineChart data={props.details}
               margin={{top: 10, right: 0, left: -15, bottom: 0}}>
      <XAxis dataKey="name"/>
      <YAxis/>
      <CartesianGrid strokeDasharray="3 3"/>
      <Tooltip/>
      <Legend/>
      <Line type="monotone" dataKey="engineHours" stroke="#FE9E15" activeDot={{r: 8}}/>
      
    
    </LineChart>
  </ResponsiveContainer>
);

class Dashboard extends Component {

    constructor() {
        super();
        this.state = {
             devices:0,
             users:0,
             vehicles:0,
             sim:0,
             vehicleCategory:[],
             details:[],
             details2:[],
             total:{}
        };
    }

    componentDidMount() {
		this.getData({});
    }
    
    
    getData = (params = {}) =>{
    	
    	
    	axios.get('get-dash-data')
		.then((response) => {
			let data = response.data;
			 
			this.setState({devices:data.devices,users:data.users,sim:data.sim,vehicles:data.vehicles, vehicleCategory:data.vehicles_category, details:data.distance_details, details2:data.working_details, total:data.total})
			console.log(data);
					})
		.catch((error) => {
			console.log(error);
		})
    	
    	
    	 
    }

    render() {
    	const {devices, users, vehicles, sim, vehicleCategory, total} = this.state;
    	
        return (
        	<> 
                 
                 
                
                <Row>
                
                <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-order-sm-1">
                   <Card> 
                   <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <IconWithTextCard cardColor="cyan" icon="diamond" title= {devices} subTitle="Devices"/>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <IconWithTextCard cardColor="orange" icon="tasks" title={vehicles} subTitle="Vehicles"/>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <IconWithTextCard cardColor="teal" icon="team" title={users} subTitle="Users"/>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <IconWithTextCard cardColor="red" icon="files" title={sim} subTitle="Sim"/>
            </Col>
             
            
          </Row>
          </Card>
                   </Col>
                
                   <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-order-sm-1">
                   <Card title={"Total Vehicles : "+ vehicles}>
                   <TinyBarChart  vehicleCategory={this.state.vehicleCategory}/>
                   </Card>
                   </Col>
               
                
                   
                
                </Row>
                
                
                <Row>
                <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-order-sm-1">
                   <Card title={"Total Distance (in KM) : " + parseInt(total.total_distance) }>
                   <SimpleLineChart details={this.state.details}/>
                   </Card>
                   </Col>
                
                <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-order-sm-1">
                   <Card title={"Total Working Hours : "+ parseInt(total.total_engineHours) }>
                   <SimpleLineChart2 details={this.state.details2}/>
                   </Card>
                   </Col>
                </Row>
                
                </>
        );
    }
}

export default Dashboard;