import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Card,Tag, Table,Button} from "antd";
import moment from 'moment';
import axios from 'axiosConfig';
import {PlusOutlined  }  from '@ant-design/icons';

class AllCategories extends Component {
	
	constructor() {
	    super();
	    this.state = {
		 loading:true,
		 data:[],
	    };
	  }
	  
	 componentDidMount() {
	 	 this.getData();
	 }
	 
	handleTableChange = (pagination, filters, sorter) => {
		const pager = { ...this.state.pagination };
		pager.current = pagination.current;
		this.getData(pagination,filters,sorter)
		 
	};
	
	getData = (params = {}) =>
	{
		this.setState({loading:true})
		axios.get('categories',{params:params})
		.then((response) => {
			let resp = response.data;
			let data = resp.data;
			this.setState({loading:false,data})
		})
		.catch((error) => {
		console.log(error);
		})
	}
	
	 
	render () {
		const {loading,data} = this.state;
		
		const columns = [{
			  title: 'Id',
			  dataIndex: 'id',
			  width: 150,
			}, {
			  title: 'Name',
			  dataIndex: 'title',
			  width: 150,
			},{
			  key:'id',
			  title: 'List',
			  width: 150,
			   render : (row) => (<Link to={`/main/vehicles/all-brands/${row.id}`}><Tag color={'#87d068'}>Brands</Tag></Link>)
			}];

		return (
			<Card 
				className="gx-card"
				title={`All Categories`}
				extra={
				<>
				<Link to="/main/vehicles/add-category"><Button type="primary" icon={<PlusOutlined/>}>Category</Button></Link>
				</>	
				}
			>
		      <Table size={'small'} 
		    	  key={'AllCategories'}
			      loading={loading} 
			      onChange={this.handleTableChange} 
			      className="gx-table-responsive" 
			      columns={columns} 
			      dataSource={data} 
			      pagination={false}
			      scroll={{y: 240}}
		      />
		    </Card>
		)
	}
	
}

export default AllCategories;
