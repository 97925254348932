import React, { Component } from "react";
import { Col, Row,message } from "antd";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import Widget from "components/Widget/index";
import Auxiliary from "util/Auxiliary";
import axios from 'axiosConfig';

const chartdata = [{ name: 'Normal', value: 58 }, { name: 'Height', value: 20 },{ name: 'Critical', value: 8 }, { name: 'Low', value: 15 }];

const COLORS = ['#5797fc', '#FA8C16', '#f5222d', '#d9d9d9'];

class Dash extends Component {

    constructor() {
        super();
        this.state = {
            loading:true,
            dashData:null,
        };
    }

    componentDidMount() {
		this.getData({});
    }
    
    
    getData = (params = {}) =>{
    	this.setState({loading:true})
    	axios.get('dashboard-data',{params:params})
    	.then((response) =>{
    		if(response.status != 200)
    		{
    			message.error('Something went wrong! please try again later');
    			return;
    		}
    		let data = response.data;
    		this.setState({dashData:data.data,loading:false})
    		
    	})
    }

    render() {
    	const {dashData,loading} = this.state;
    	
    	if(loading)
    		return null;
    		
    	console.log('dashData',dashData);
    	
        return (
            <Auxiliary>
                <Row>
                    <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                        <Widget title={ <h2 className="h4 gx-text-capitalize gx-mb-0"> Vehicle Status</h2> } 
                        styleName="gx-text-center">
                            <div className="gx-py-3"> 
                                <ResponsiveContainer width="100%" height={150}>
                                    <PieChart>
                                        <Tooltip />
                                        <text x="50%" className="h1" y="50%" textAnchor="middle" dominantBaseline="middle">
                                            27
										</text>
                                        <Pie data={chartdata} dataKey="value" cx="50%" cy="50%"
                                            innerRadius={47}
                                            outerRadius={57}
                                            fill="#8884d8"
                                        >
                                            {
                                                chartdata.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                                            }
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </Widget>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                        <Widget styleName="gx-pink-purple-gradient gx-text-white gx-card-1367-p">
                            <h2 className="gx-font-weight-semi-bold gx-text-white"> Active Sim</h2>
                            <p className="gx-text-uppercase gx-fs-sm gx-mb-0">100 Total sim</p>
                        </Widget>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                        <Widget styleName="gx-pink-purple-gradient gx-text-white gx-card-1367-p">
                            <h2 className="gx-font-weight-semi-bold gx-text-white">{`${dashData.installed_device} Active devices`} </h2>
                            <p className="gx-text-uppercase gx-fs-sm gx-mb-0">{`${dashData.new_device + dashData.installed_device} TOTAL DEVICES`}</p>
                        </Widget>
                    </Col>
                </Row>
            </Auxiliary>
        );
    }
}

export default Dash;