import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Button, Card, Checkbox, Form, Input, message, Select, Row, Col } from "antd"
import { UnorderedListOutlined } from "@ant-design/icons"
import axios from "axiosConfig"

const FormItem = Form.Item
const { Option } = Select

const layout = {
	labelCol: {
		span: 12,
	},
	wrapperCol: {
		span: 12,
	},
}

class AppendUser extends Component {
	formRef = React.createRef()
	searchformRef = React.createRef()

	constructor() {
		super()
		this.state = {
			loading: true,
			btnLoading: false,
			categories: [],
			makers: [],
			models: [],
			sims: [],
			device: null,
		}
	}

	componentDidMount() {
		const {deviceId} = this.props.match.params;
		
		this.setState({deviceId},() =>{
			this.getDeviceDetail({deviceId})
		})
	}
	
	
	getDeviceDetail = (params = {}) =>{
		this.setState({loading:true})
		axios.get(`devices/${params.deviceId}`)
		.then((response) => {
			let resp = response.data;
			let device = resp.data;
			let category_id = device.attributes.category_id;
			let maker_id	= device.attributes.make_id;
			
			this.setState({device,loading:false}, () => {
				this.getCategories();
				this.getMakers({category_id});
				this.getModels({category_id, maker_id});
			})
		})
		.catch((error) => {
			console.log(error);
		})
	}
	
	

	getCategories = () =>{
		axios.get('categories').then((response) =>{
			let resp		= response.data;
			let categories  = resp.data;
			this.setState({categories,loading:false})
		})
	}
	
	getMakers = (params = {}) =>{
		this.setState({loading:true})
		axios.get('brands?all=1',{params:params})
		.then((response) => {
			let resp = response.data;
			let makers = resp.data;
			
			this.setState({loading:false,makers})
		})
		.catch((error) => {
			console.log(error);
		})
	}

	getModels = (params = {}) =>{
		this.setState({loading:true})
		axios.get('models?all=1',{params:params})
		.then((response) => {
			let resp = response.data;
			let models = resp.data;
			
			this.setState({loading:false,models})
		})
		.catch((error) => {
			console.log(error);
		})
	}

	onSelectCategory = (value) => {
		let category_id = value
		this.setState({ category_id: value }, () => {
			this.getMakers({ category_id })
		})
	}

	onSelectMaker = (value) => {
		const { category_id } = this.state
		let maker_id = value

		this.setState({ maker_id: maker_id }, () => {
			this.getModels({ category_id, maker_id })
		})
	}

	onFinish = (values) => {
		this.setState({ btnLoading: true })
		axios.put(`devices/${values.device_id}`, values)
			.then((response) => {
				let data = response.data
				if (data.success) 
				{
					message.success(data.message)
					this.formRef.current.resetFields()
				}
				else 
					message.error(data.message)

				this.setState({ btnLoading: false })
			})
			.catch((error) => {
			let resp = error.response
		  	if(resp.status == 422)
		  	{
		  		let data = resp.data;
		  		
		  		Object.keys(data).map((item)=>{
		  			 message.error(data[item][0]);
		  		})
		  	}
		  	else
		    	message.error('Something went wrong! Please try again');
		    	
		    this.setState({btnLoading:false});
		  })
	}

	onReset = () => {
		this.formRef.current.resetFields()
	}

	render() {
		const { device, loading, btnLoading, categories, makers, models,sims } = this.state
		if(device == null)
			return null;
		
		const {attributes} = device;
	 
			
		return (
		 
				<Card
					title={`Update Device`}
					className="gx-card"
					extra={<Link to="/main/devices">{" "}
							<Button type="primary" icon={<UnorderedListOutlined />}>
								All Devices
							</Button>
						</Link>
					}
				>
			 
						<Row>
							<Col xs={24} sm={24} md={12} lg={12} xl={12}>
								<Form
									{...layout}
									name="add_device"
									ref={this.formRef}
									onFinish={this.onFinish}
									colon={false}
									initialValues={{
										device_id		:	device.id,
										name			:	device.name,
										category_id		:	attributes.category_id,
										model_id		:	attributes.model_id,
										make_id			:	attributes.make_id,
										reg_no			:	attributes.regno,
										sim_number		:	device.sim_number
									}}
								>
									<FormItem name="device_id" hidden>
										<Input type="hidden" disabled />
									</FormItem>
									
									<FormItem
										label="name"
										rules={[
											{ required: true, message: "Name is required" },
										]}
										name="name"
									//	extra="i.e: 8991000000000000000"
									>
										<Input placeholder="Sim Number required" />
									</FormItem>
								 
									<Form.Item
										name="category_id"
										label="Vehicle Type"
										rules={[{ required: true }]}
									>
										<Select
											name="category_id"
											placeholder="Select Vehicle Type"
											onChange={this.onSelectCategory}
											filterOption={(input, option) =>
												option.props.datavalue
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0
											}
											allowClear
											showSearch
										>
											{categories.map((category, index) => {
												return (
													<Option
														key={category.id}
														datavalue={category.title}
														value={category.id}
													>
														{category.title.toUpperCase()}
													</Option>
												)
											})}
										</Select>
									</Form.Item>

									<Form.Item
										name="make_id"
										label="Make"
										rules={[{ required: true }]}
									>
										<Select
											name="make_id"
											placeholder="Select Vehicle Makers"
											onChange={this.onSelectMaker}
											filterOption={(input, option) =>
												option.props.datavalue
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0
											}
											allowClear
										>
											{makers.map((maker, index) => {
												return (
													<Option
														key={maker.id}
														datavalue={maker.title}
														value={maker.id}
													>
														{maker.title.toUpperCase()}
													</Option>
												)
											})}
										</Select>
									</Form.Item>

									<Form.Item
										name="model_id"
										label="Model"
										rules={[{ required: true }]}
									>
									<Select placeholder="Select Vehicle Models" 
										name="model_id"
										filterOption={(input, option) => option.props.datavalue.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										allowClear
										showSearch
										>
											{models.map((model, index) => {
												return (
													<Option
														key={model.id}
														datavalue={model.title}
														value={model.id}
													>
														{model.title.toUpperCase()}
													</Option>
												)
											})}
										</Select>
									</Form.Item>
									
									<FormItem
										label="Sim S/N"
										rules={[
											{ required: true, message: "Sim Number required" },
										]}
										name="sim_number"
									//	extra="i.e: 8991000000000000000"
									>
										<Input placeholder="Sim Number required" />
									</FormItem>

									<FormItem
										label="Registation No."
										rules={[
											{ required: true, message: "GPS Device id missing!" },
										]}
										name="reg_no"
									>
										<Input placeholder="Enter Vehicle Registartion No" />
									</FormItem>

								 
									<FormItem {...layout} label="&nbsp;">
										<Button
											type="primary"
											htmlType="submit"
											loading={btnLoading}
										>
											Update
										</Button>
									</FormItem>
								</Form>
							</Col>
						</Row>
					</Card>
			 
		)
	}
}

export default AppendUser
