import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Button, Card, Checkbox, Form, Input, message, Select, Row, Col } from "antd"
import { UnorderedListOutlined } from "@ant-design/icons"
import axios from "axiosConfig"

const FormItem = Form.Item
const { Option } = Select

const layout = {
	labelCol: {
		span: 12,
	},
	wrapperCol: {
		span: 12,
	},
}

class UpdatePlan extends Component {
	formRef = React.createRef()
	searchformRef = React.createRef()

	constructor() {
		super()
		this.state = {
			loading: true,
			btnLoading: false,
			plan: null,
		}
	}

	componentDidMount() {
		const {planid} = this.props.match.params.planid;
		console.log(planid);
		this.setState({planid},() =>{
			this.getPlan({planid})
		})
	}
	
	
	getPlan = (params = {}) =>{
		this.setState({loading:true})
		axios.get(`plan/${params.planid}`)
		.then((plan) => {
			
			this.setState({plan,loading:false})
		})
		.catch((error) => {
			console.log(error);
		})
	}
	
	onFinish = (values) => {
		this.setState({ btnLoading: true })
		axios.put(`devices/${values.device_id}`, values)
			.then((response) => {
				let data = response.data
				if (data.success) 
				{
					message.success(data.message)
					this.formRef.current.resetFields()
				}
				else 
					message.error(data.message)

				this.setState({ btnLoading: false })
			})
			.catch((error) => {
			let resp = error.response
		  	if(resp.status == 422)
		  	{
		  		let data = resp.data;
		  		
		  		Object.keys(data).map((item)=>{
		  			 message.error(data[item][0]);
		  		})
		  	}
		  	else
		    	message.error('Something went wrong! Please try again');
		    	
		    this.setState({btnLoading:false});
		  })
	}

	onReset = () => {
		this.formRef.current.resetFields()
	}

	render() {
		const { plan, loading, btnLoading } = this.state;
	console.log(plan);
		return (
		 
				<Card
					title={`Update Device`}
					className="gx-card"
					extra={<Link to="/main/devices">{" "}
							<Button type="primary" icon={<UnorderedListOutlined />}>
								All Devices
							</Button>
						</Link>
					}
				>
			 
						<Row>
							<Col xs={24} sm={24} md={12} lg={12} xl={12}>
								<Form
									{...layout}
									name="update_plan"
									ref={this.formRef}
									onFinish={this.onFinish}
									colon={false}
									initialValues={{
										plan_id			:	plan.id,
										plan_days		:	plan.plan_days,
										plan_price		:	plan.plan_price,
										plan_dis_price	:	plan.plan_dis_price,
										plan_status		:	plan.plan_status,
									}}
								>
								<FormItem name="plan_id" hidden>
									<Input type="hidden" disabled />
								</FormItem>
									
								<FormItem
									label="Validity"
									rules={[
										{ required: true, message: "Validity Is required" },
									]}
									name="plan_days"
								>
									<Input placeholder="Validity Required" />
								</FormItem>
								 
									
									<FormItem {...layout} label="&nbsp;">
										<Button
											type="primary"
											htmlType="submit"
											loading={btnLoading}
										>
											Update
										</Button>
									</FormItem>
								</Form>
							</Col>
						</Row>
					</Card>
			 
		)
	}
}

export default UpdatePlan
