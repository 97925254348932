import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Card,Tag, Table,Button,Upload,message} from "antd";
import moment from 'moment';
import axios from 'axiosConfig';
import {PlusOutlined ,UploadOutlined  }  from '@ant-design/icons';

class AllSim extends Component {
	
	constructor() {
	    super();
	    this.state = {
		 loading:true,
		 data:[],
		 fileList: [],
		 pagination :{ current:1,defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '50','100','250']}
	    };
	  }
	  
	 componentDidMount() {
	 	const {pagination} = this.state;
	 	let pageSize: pagination.defaultPageSize;
	 	let current	: pagination.current;
	 	 this.getData({current,pageSize});
	 }
	 
	handleTableChange = (pagination, filters, sorter) => {
		const pager = { ...this.state.pagination };
		pager.current = pagination.current;
		this.getData(pagination,filters,sorter)
	};
	
	getData = (params = {}) =>
	{
		this.setState({loading:true})
		const {pagination} =  this.state ;
	 
		axios.get('sims',{params:params})
		.then((response) => {
			let resp = response.data;
			let data = resp.data;
			
			pagination.total = data.total;
			pagination.current = data.current_page;
			
			this.setState({loading:false,data,pagination})
		})
		.catch((error) => {
			console.log(error);
		})
	}
	
	
	handleUpload = () => {
		
	    const { fileList } = this.state;
	    const formData = new FormData();
	    
	     
	    formData.append('sim_csv_file', fileList[0]);
	     
	    
	    this.setState({
	      uploading: true,
	    });
	
	    // You can use any AJAX library you like
	    axios.post('upload-sims-csv', formData, {
	        headers: {
	          'Content-Type': 'multipart/form-data'
	        }
	    })
	    .then((response) => {
			message.success('upload successfully.');
		})
		.catch((error) => {
			message.error('upload failed.');
		})
	    
	     
	  };
	
	 
	render () {
		const {loading,data,pagination } = this.state;
		let {fileList } = this.state;
		
		const uploadprops = {
			 
			beforeUpload: file => {
			 
				
				fileList = [file]
				
				this.setState( {fileList},()=>{
					this.handleUpload();
				});
				return false;
			},
			showUploadList:false
			};
		
		
	  
		
		const columns = [{
		  title: 'Provider',
		  dataIndex: 'provider',
		  width: 150,
		}, {
		  title: 'Sim Number',
		  dataIndex: 'mobile',
		  width: 150,
		}, {
		  title: 'Sim Id',
		  dataIndex: 'sim_id',
		},
		{
		  title: 'Status',
		  dataIndex: 'status',
		  render : status => (<Tag color={status === 'Active' ? '#f50' : '#87d068'} key={status}>
		              {status.toUpperCase()}
		            </Tag>)
		},
		{
			title: 'Date',
			dataIndex: 'created_at',
			render: text => moment(text).format("D MMM YY h:mm:ss a"),
		}];
		

		return (
			<Card 
				title={`All Sim Inventory ( ${data.total} )`}
				extra={<><Upload {...uploadprops}><Button type="primary"icon={<UploadOutlined  />}>Upload Sim CSV</Button></Upload><Link to="/main/sim/add"> <Button type="primary"icon={<PlusOutlined />}>New Sim</Button></Link></>}
			>
		      <Table size={'small'} 
			      loading={loading} 
			      onChange={this.handleTableChange} 
			      className="gx-table-responsive" 
			      columns={columns} 
			      dataSource={data.data} 
			      pagination={pagination}
		      />
		    </Card>
		)
	}
	
}

export default AllSim;
