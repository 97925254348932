
import axios from 'axios';

import configureStore, {history} from "./appRedux/store";
import {userSignOut} from "./appRedux/actions/Auth";
export const store = configureStore();

const instance =  axios.create({
  baseURL: 'http://admin.gopista.com/api-v1/admin/',
  timeout: 5000,
});

instance.interceptors.request.use((config) =>  {
	let url = config.url;
	if(url.indexOf('api-v1/signin') == -1)
	{
	    const token = JSON.parse(localStorage.getItem('user')).token;
	    config.headers.Authorization =  `Bearer ${token}`;
	}
    return config;
});


instance.interceptors.response.use((response) => {
	    return response;
	  }, (error) => {
	  	const {status} = error.response;
	    if (status === 403 || status === 401 || status === 400 || status === 399 ) {
	    	store.dispatch(userSignOut());
	    }
	    return Promise.reject(error);
	  });

export default instance;