import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Card,Tag, Table,Button,Tooltip,message,Input} from "antd";
import {UserAddOutlined  }  from '@ant-design/icons';
import moment from 'moment';
import axios from 'axiosConfig';

	

class AllSim extends Component {
	
	constructor() {
	    super();
	    this.state = {
		 loading:true,
		 data:[],
		 pagination :{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}
	    };
	  }
	  
	 componentDidMount() {
	 	 this.getData();
	 }
	 
	handleTableChange = (pagination, filters, sorter) => {
		const pager = { ...this.state.pagination };
		pager.current = pagination.current;
		this.getData(pagination,filters,sorter)
		 
	};
	
	handleChnageStatus = (id,status) =>{
		axios.post('update-user',{id,status})
		.then((response) => {
			let data =  response.data;
			
			if(data.success)
				message.success(data.message)
			else
				message.error(data.message)
			
			this.getData();
		})
		.catch((error) =>{
			message.error('Error! Please Try again')
		})
	}
	
	getData = (params = {}) =>
	{
		this.setState({loading:true})
		const {pagination} =  this.state ;
		
		axios.get('users',{params:params}) 
		.then((response) => {
			let data = response.data.data;
			pagination.total = data.total;
			
			this.setState({loading:false,data,pagination})
		})
		.catch((error) => {
			console.log(error);
		})
	}
	
	render () {
	const {loading,data,pagination} = this.state;
	const columns = [
	{
	  title: 'Id',
	  dataIndex: 'id',
	  width:50
	},{
		
	  title: 'Name',
	  dataIndex: 'name',
	  width: 150,
	}, {
	  title: 'Mobile',
	  dataIndex: 'phone',
	  width: 150,
	}, {
	  title: 'Email',
	  dataIndex: 'email',
	}
	, {
	  title: 'Status',
	  dataIndex: 'status',
	  render : status => (<Tag color={status == 'Active' ? '#87d068' : '#f50'} key={status}> {status.toUpperCase()}</Tag>)
	}
	,{
		title: 'Date',
		dataIndex: 'created_at',
		render: text => moment(text).format("DD MMM YY"),
	}
	,{
		title: 'Actions',
		render: (row) => (<Tooltip title="Delete">
					      <Button  type="danger" onClick={() => this.handleChnageStatus(row._id,'Deleted')} size={'small'} shape="circle" icon={<i className="icon icon-trash"/>} />
					    </Tooltip>
					    ),
	}];
	
		return (
			<Card 
				title={`All Users ( ${data.total} )`}
				extra={<Link to="/main/users/add"> <Button type="primary"icon={<UserAddOutlined />}>New User</Button></Link>}
			>
		      <Table size={'small'} 
			      loading={loading} 
			      onChange={this.handleTableChange} 
			      className="gx-table-responsive" 
			      columns={columns} 
			      dataSource={data.data} 
			      pagination={pagination}
		      />
		    </Card>
		)
	}
	
}

export default AllSim;
