const data = [
  {name: 'Page A', uv: 4000, price: 2400, amt: 2400},
  {name: 'Page B', uv: 3000, price: 1398, amt: 2210},
  {name: 'Page C', uv: 2000, price: 9800, amt: 2290},
  {name: 'Page D', uv: 2780, price: 3908, amt: 2000},
  {name: 'Page E', uv: 1890, price: 4800, amt: 2181},
  {name: 'Page F', uv: 2390, price: 3800, amt: 2500},
  {name: 'Page G', uv: 3490, price: 4300, amt: 2100},
];
export default data;
