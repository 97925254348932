import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import AddSim from "./Add";
import AllSim from "./All";

const SimRoute = ({match}) => (
  <Switch>
	<Route path={`${match.url}/add`} component={AddSim}/>
	<Route path={`${match.url}`} component={AllSim}/>
  </Switch>
);

export default SimRoute;
