import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Card, Checkbox, Form, Input, message,Select,Col} from "antd";
import {UnorderedListOutlined } from '@ant-design/icons';
import axios from 'axiosConfig';

const FormItem = Form.Item;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

class AddDevice extends Component {
	
	 formRef = React.createRef();
	 
	constructor() {
	    super();
	    this.state = {
			loading:true,
			btnLoading:false,
			sims : []
	    };
	  }
	  
	 componentDidMount() {
	 	this.getNewSims();
	 }
	 
	 getNewSims = () =>{
		axios.get('sims?all=1').then((response) =>{
			let resp = response.data;
			let data = resp.data;
			
			this.setState({sims:data,loading:false})
		})
	}
	 
	 
	  onFinish = (values) => {
	  	this.setState({btnLoading:true});
		axios.post('devices',values)
		.then((response) => {
			let data = response.data;
			if(data.success)
			{
				message.success(data.message);
				this.formRef.current.resetFields();
			}
			else
			{
				message.error(data.message);
			
			}
			
			this.setState({btnLoading:false});
	  })
	  .catch((error) => {
	   let resp = error.response
	  	if(resp.status == 422)
	  	{
	  		let data = resp.data;
	  		
	  		Object.keys(data).map((item)=>{
	  			 message.error(data[item][0]);
	  		})
	  	}
	  	else
	    	message.error('Something went wrong! Please try again');
	    	
	    this.setState({btnLoading:false});
	  })
	 
	 }
	 
	 
	 onReset = () => {
		    this.formRef.current.resetFields();
	 };
	 
	 render() {
	 	const {sims,loading,btnLoading} = this.state;
	    return (
	    	<Card className="gx-card" title="Add New Device" extra={<Link to="/main/devices"> <Button type="primary" icon={<UnorderedListOutlined/>}>All Devices</Button></Link>}>
	    		<Col xs={24} xl={12} md={12} lg={12} sm={24}>
	    		<Form
					{...layout}
					name="add_device"
					ref={this.formRef} 
					initialValues={{ remember: true }}
					onFinish={this.onFinish}
					colon={false}
				>
			          
		           <Form.Item name="make" label="Make" rules={[{ required: true }]}>
			        <Select
			          placeholder="Select Device Make/Brand"
			          allowClear
			        >
			          <Option value="teltonika">Teltonika</Option>
			        </Select>
			      </Form.Item>
				      
				      <Form.Item name="model" label="Model" rules={[{ required: true }]}>
				        <Select
				          placeholder="Select Device Model"
				          allowClear
				        >
				          <Option value="fmb920">FMB 920</Option>
				        </Select>
				      </Form.Item>
				      
			          <FormItem
			        	label="IMEI"
	        			rules={[{ required: true, message: 'Please input IMEI!' }]}
	                    name="imei"
			           >
			            <Input placeholder="IMEI"/>
			          </FormItem>
			          
			          <Form.Item name="sim_id" label="SIM" rules={[{ required: true }]}>
				        <Select
				          placeholder="Select SIM ID"
				          allowClear
				          showSearch
				          filterOption={(input, option) => option.props.datavalue.toLowerCase().indexOf(input.toLowerCase()) >= 0}
				        >
				            {
					        	sims.map((item,index) =>{
					        		return <Option key={item.id} datavalue={item.sim_id} value={item.id}>{item.sim_id}</Option>
					        	})
					        }
				        </Select>
				      </Form.Item>
			         
			          
			          <FormItem label="&nbsp;">
			            <Button type="primary" htmlType="submit" loading={btnLoading}>
			              Save
			            </Button>
			             <Button htmlType="button" onClick={this.onReset} >
				            Reset
				          </Button>
			          </FormItem>
			        </Form>
			       </Col>
	    	</Card>
	    );
  }
}


export default (AddDevice);
