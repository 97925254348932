import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import Tracking from "./DevicesLiveTracking";
import DeviceDetails from "./New_Device_Detail";

const TrackingRoute = ({match}) => (
  <Switch>
	<Route path={`${match.url}/:imei`} component={DeviceDetails}/>
	<Route path={`${match.url}`} component={Tracking}/>
  </Switch>
);

export default TrackingRoute;
