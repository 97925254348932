import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import AddCategory from "./AddCategory";
import AllCategories from "./AllCategories";
import AllCategoryBrands from "./AllCategoryBrands";
import AllModels from "./AllModels";
import AddMake from "./AddMake";
import AddModel from "./AddModel";
import UpdateModel from "./UpdateModel";

const VehicleRoute = ({match}) => (
  <Switch>
    <Route path={`${match.url}/add-category`} component={AddCategory}/>
    <Route path={`${match.url}/add-make`} component={AddMake}/>
    <Route path={`${match.url}/add-model`} component={AddModel}/>
    <Route path={`${match.url}/all-brands/:category_id`} component={AllCategoryBrands}/>
    <Route path={`${match.url}/all-models/:category_id/:maker_id`} component={AllModels}/>
    <Route path={`${match.url}/update-model/:model_id`} component={UpdateModel}/>
    <Route path={`${match.url}`}	component={AllCategories}/>
  </Switch>
);

export default VehicleRoute;
