import React, {Component} from "react";
import {Link} from "react-router-dom";
import fetch from "isomorphic-fetch";
import {Row,Col,Card,Tabs,Radio,Button,DatePicker,Spin,Collapse} from "antd";
import { GoogleMap, LoadScript,Polyline,MarkerClusterer, Marker  } from '@react-google-maps/api';
import { SyncOutlined,PoweroffOutlined} from '@ant-design/icons';
import CustomScrollbars from "util/CustomScrollbars";
import axios from 'axiosConfig';
import moment from 'moment';
import LoadMap from './LoadMap';

const google = window.google;
const dateFormat = 'MMMM Do YYYY';

const mapContainerStyle = {
  height: "100%",
  width: "100%"
};

 const markeroptions = {
  imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
};

const options = {
  strokeColor: '#414949',
  strokeOpacity: 1.0,
  strokeWeight: 4,
  fillColor: '#414949',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1
};



const timeDiffrence = (start,end) =>{
	start	= moment(start,'DD-MM-YYYY hh:mm:ss');
	end 	=	moment(end,'DD-MM-YYYY hh:mm:ss');
	
	var diff = end.diff(start,'seconds');
	var hour,minutes,seconds,timeString = '';
	
	hour = Math.floor(diff/3600);
	diff = diff%3600;
	
	minutes = Math.floor(diff/60);
	diff = diff%60; 
	
	seconds = diff;
	
	if(hour > 0)
		timeString += hour+' hrs';
	 
	timeString += minutes+ ' mins ' +seconds+' secs' ;
	
	return  timeString;
}


export default class DeviceDetails extends Component {
	
  isUnmounted :false;
  constructor() {
    super();
    this.state = {
    	showSingleTripDetails : false,
		intervalId: null,
		imei:null,
		tripCoardinates:[],
		tripStartPoints: [],
		allTrips: [],
		center:null,
		loading:true,
    };
  }

  componentDidMount() {
  	const { imei } = this.props.match.params;
  	this.setState({imei},() => {
  		this.getDeviceLocation({imei});
  	})
  }
  
  getDeviceLocation(params = {}) {
  	const {imei} = this.state;
    axios.get('get-device-date-trip',{params:params})
      .then((response) => {
        let alldata = response.data;
        
        let tripStartPoints = [];
        let markers = [];
        
        let gpsdata = null;
        let i = 0;
        for(i=0; i<alldata.length; i++)
        {
        	let item = alldata[i];
        	gpsdata    = item.gps;
        	
        	 tripStartPoints.push({
	        	'lat' : gpsdata[0].gps.latitude,
	        	'lng' : gpsdata[0].gps.longitude,
	        })
	        
	        markers.push( {
	        	'lat' : gpsdata[0].gps.latitude,
	        	'lng' : gpsdata[0].gps.longitude,
	        	'icon'	: `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red${i+1}.png`
	        } );
        }
        
    	markers.push( {
        	'lat' : gpsdata[gpsdata.length -1].gps.latitude,
        	'lng' : gpsdata[gpsdata.length -1].gps.longitude,
        	'icon'	: `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red${i+1}.png`
        });
	        
        let center = tripStartPoints[Math.floor(tripStartPoints.length/2)];
        
        //markers.push( tripStartPoints[tripStartPoints.length-1] )
        
        this.setState({allTrips:alldata,tripStartPoints,center,markers,loading:false})
        
      })
      .catch((error) => {
        console.log(error);
      })
  }
  
  componentWillUnmount() {
    this.isUnmounted = true;
  }
  
  handleDateChange = (date) => {
  	const {imei} = this.state;
  	date = moment(date).format('YYYY-MM-DD');
  	this.getDeviceLocation({imei,date});
  }
  
  handleOnTripClick = (tripDetails) =>{
  	console.log(tripDetails.gps);
  	 let allData = tripDetails.gps;
  	let tripStartPoints = [];
  	let markers = [];
  	
  	for(let i=0; i<allData.length; i++)
    {
    	if(allData[i].gps.latitude != 0 && allData[i].gps.longitude != 0)
    	{
		  	tripStartPoints.push({
		    	'lat' : allData[i].gps.latitude,
		    	'lng' : allData[i].gps.longitude,
		    })
    	}
    }
    
	let center = tripStartPoints[Math.floor(tripStartPoints.length/2)];
    markers.push( tripStartPoints[0] );
    markers.push( tripStartPoints[tripStartPoints.length-1] )
    
    this.setState({tripStartPoints,center,markers,showSingleTripDetails:true})
  }
  
  
  
  render() {
  	const {loading,allTrips,tripStartPoints,center,markers,imei} = this.state;
  	
  	if( loading )
  		return <Spin indicator={<SyncOutlined style={{fontSize:30}} spin />} />;
	
    return (
    	
    		<Row style={{height:'100%',width:'100%',background:'#fff'}}>
    			<Col xl={8} md={8} sm={8} xs={24} style={{padding:0}}>
				    <div class="gx-chat-sidenav-header">
					   <div class="gx-chat-user-hd" style={{marginBottom:0}}>
					      <div class="gx-chat-avatar gx-mr-3">
					         <div class="gx-status-pos">
					            <span id="avatar-button" class="ant-avatar gx-size-50 ant-avatar-circle ant-avatar-image">
					            <img src="https://via.placeholder.com/150" alt=""/>
					            </span><span class="gx-status gx-online"></span>
					         </div>
					      </div>
					      <div class="gx-module-user-info gx-flex-column gx-justify-content-center">
					         <div class="gx-module-title">
					            <h5 class="gx-mb-0">1PY5405ECKA000604</h5>
					            <h6 class="gx-mb-0">JOHN DEERE 5405</h6>
					         </div>
					         <div class="gx-module-user-detail"><span class="gx-text-grey gx-link">Update time: 7/7/2020, 10:15 AM IST</span></div>
					      </div>
					   </div>
					</div>
					<CustomScrollbars className="gx-layout-sider-scrollbar">
					<Row style={{margin:0,borderBottom:'1px solid #eee'}}>
						<Col md={24} style={{margin:8}}>
							<Link to={`/main/live-tracking/${imei}`}><Button  type="link">Details</Button></Link>
							<Link to={`/main/live-tracking/${imei}/trips`}><Button type="primary">Trips</Button></Link>
							<Link to={`/main/live-tracking/${imei}/summary`}><Button type="link">Summary</Button></Link>	
						</Col>
						<Col md={24} style={{margin:8}}>
							<DatePicker onChange={this.handleDateChange} defaultValue={moment(new Date(), dateFormat)} format={dateFormat} style={{width:'100%'}}/>
						</Col>
					</Row>
					
					<Row style={{margin:8}}>
						{
							allTrips.map((item,index) =>{
							    return <Card style={{width:'100%'}} title={`Trip#${index+1}`} extra={<Button onClick={() => this.handleOnTripClick(item)} type="link">Show</Button>}  key={index}>
									      <Row>
									    	<Col md={12}>
									    		<div class="gx-module-title">
									    			<div class="gx-module-user-detail"><span class="gx-text-grey gx-link">START TIME</span></div>
								            		<h5 class="gx-mb-0">{moment(item.started_at).format('hh:mm A')}</h5>
										         </div>
									    	</Col>
									    	<Col md={12}>
									    		<div class="gx-module-title">
									    			<div class="gx-module-user-detail"><span class="gx-text-grey gx-link">END TIME</span></div>
								            		<h5 class="gx-mb-0">{moment(item.ended_at).format('hh:mm A')}</h5>
										         </div>
									    	</Col>
									      </Row>
									      <Row>
									    	<Col md={12}>
									    		<div class="gx-module-title">
									    			<div class="gx-module-user-detail"><span class="gx-text-grey gx-link">DISTANCE</span></div>
								            		<h5 class="gx-mb-0">{(item.distance/1000).toFixed(2)} km</h5>
										         </div>
									    	</Col>
									    	<Col md={12}>
									    		<div class="gx-module-title">
									    			<div class="gx-module-user-detail"><span class="gx-text-grey gx-link">TRIP DURATION</span></div>
								            		<h5 class="gx-mb-0">{timeDiffrence(item.started_at,item.ended_at)}</h5>
										         </div>
									    	</Col>
									      </Row>
									    </Card>
							})
						}
					</Row>
					</CustomScrollbars>	
				</Col>
				<Col  xl={16} md={16} sm={16} xs={24} style={{padding:0}}>
			        <LoadMap>
				        <GoogleMap
							    id="marker-example"
							    mapContainerStyle={mapContainerStyle}
							    zoom={2}
							    center={center}
							    onLoad={map => {
								    const bounds = new window.google.maps.LatLngBounds();
								    for(let i=0 ; i < tripStartPoints.length ; i++ )
								    	bounds.extend(tripStartPoints[i]);
			
								    map.fitBounds(bounds);
								  }}
							>
				           <Polyline
						      path={tripStartPoints}
						      options={options}
						    />
						     
						      { 
						        markers.map((marker,index) => (
						          <Marker icon={marker.icon} key={index} position={marker} />
						        ))
						      }
						     
				        </GoogleMap>
			      </LoadMap>
		      </Col>
    		</Row>
       
    );
  }
}
