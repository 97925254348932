import React, { Component } from "react";
import { Avatar, Button, Drawer, Input, Tabs,Row,Col,Card ,DatePicker} from "antd";
import CustomScrollbars from "util/CustomScrollbars";
import CircularProgress from "../../../components/CircularProgress/index";
import { GoogleMap, LoadScript,InfoWindow,Polyline,MarkerClusterer, Marker  } from '@react-google-maps/api';
import axios from 'axiosConfig';
import moment from 'moment';
import LoadMap from './LoadMap';

const TabPane = Tabs.TabPane;
const google = window.google;
const dateFormat = 'MMMM Do YYYY';
const mapContainerStyle = {
  height: "100%",
  width: "100%"
};

const timeDiffrence = (start,end) =>{
	start	= moment(start,'DD-MM-YYYY hh:mm:ss');
	end 	=	moment(end,'DD-MM-YYYY hh:mm:ss');
	
	var diff = end.diff(start,'seconds');
	var hour,minutes,seconds,timeString = '';
	
	hour = Math.floor(diff/3600);
	diff = diff%3600;
	
	minutes = Math.floor(diff/60);
	diff = diff%60; 
	
	seconds = diff;
	
	if(hour > 0)
		timeString += hour+' hrs';
	 
	timeString += minutes+ ' mins ' +seconds+' secs' ;
	
	return  timeString;
}


const options = {
  strokeColor: '#414949',
  strokeOpacity: 1.0,
  strokeWeight: 4,
  fillColor: '#414949',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1
};

class New_Device_Detail extends Component {
	
  isUnmounted :false;
    constructor() {
    super();
    this.state = {
		drawerState: false,
		selectedTabIndex: 1,
		intervalId: null,
		imei:null,
		markers: {},
		vehicleInfo:{},
		allTrips:[],
		tripCenter :{},
		tripMarkers :[],
		tripStartPoints: [],
		loading:true,
		currentLocation:'Getting Locations'
    }
  }

  componentDidMount() {
  	const { imei } = this.props.match.params;
  	this.setState({imei},() => {
  		this.getDeviceLocation();
  		this.getDeviceTrips({imei});
  	})
  	
  	this.state.intervalId = setInterval(()=>{
      this.getDeviceLocation();
    }, 10000);
  }
  
  getAddress = (latlang) => {
	  	var geocoder = new window.google.maps.Geocoder;
		   geocoder.geocode({'location': latlang}, (results, status) =>{
	      if (status === 'OK') {
	        if (results[0]) {
	         
	        	return results[0].formatted_address
	        } else {
	          window.alert('No results found');
	        }
	      } else {
	        window.alert('Geocoder failed due to: ' + status);
	      }
	    });
  }
  
  getDeviceLocation() {
  	const {imei} = this.state;
  
    axios.get('get-device-locations',{params:{imei}})
      .then((response) => {
        let alldata 	= response.data;
        let gpsdata 	= alldata.data.gps;
        let ioElements	= alldata.data.ioElements;
        
        let vehicleInfo = {};
        vehicleInfo.imei 	= alldata.imei;
        vehicleInfo.make 	= alldata.make;
        vehicleInfo.model 	= alldata.model;
        vehicleInfo.reg_no 	= alldata.reg_no;
        vehicleInfo.last_update 	= alldata.data.timestamp;
        vehicleInfo.speed			=	gpsdata.speed;
       
        
        ioElements.forEach((ioitem) => {
        	if(ioitem.id == 239)
        		vehicleInfo.ignition = ioitem.value;
        	if(ioitem.id == 66)
        	 vehicleInfo.exVoltage		=	(ioitem.value/1000).toFixed(2);
        	 if(ioitem.id == 16)
        		vehicleInfo.totalDistance =	(ioitem.value/1000).toFixed(2);
        })
        
        
	    let  markers = {lat:gpsdata.latitude, lng:gpsdata.longitude};
	    
	    //let currentLocation = this.getAddress(markers);
	  
	    this.setState({markers,vehicleInfo,loading:false});
        
      })
      .catch((error) => {
        console.log(error);
      })
  }
  
  getDeviceTrips(params = {}) {
    axios.get('get-device-date-trip',{params:params})
      .then((response) => {
        let alldata = response.data;
        let tripStartPoints = [];
        let tripMarkers = [];
        
        let gpsdata = null;
        let i = 0;
        for(i=0; i<alldata.length; i++)
        {
        	let item = alldata[i];
        	gpsdata    = item.gps;
        	
        	 tripStartPoints.push({
	        	'lat' : gpsdata[0].gps.latitude,
	        	'lng' : gpsdata[0].gps.longitude,
	        })
	        
	        tripMarkers.push( {
	        	'lat' : gpsdata[0].gps.latitude,
	        	'lng' : gpsdata[0].gps.longitude,
	        	'icon'	: `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red${i+1}.png`
	        } );
        }
        
    	// tripMarkers.push( {
     //   	'lat' : gpsdata[gpsdata.length -1].gps.latitude,
     //   	'lng' : gpsdata[gpsdata.length -1].gps.longitude,
     //   	'icon'	: `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red${i+1}.png`
     //   });
	        
        let tripCenter = tripStartPoints[Math.floor(tripStartPoints.length/2)];
        
        this.setState({allTrips:alldata,tripStartPoints,tripCenter,tripMarkers,loading:false})
        
      })
      .catch((error) => {
        console.log(error);
      })
  }
  
  handleDateChange = (date) => {
  	const {imei} = this.state;
  	date = moment(date).format('YYYY-MM-DD');
  	this.getDeviceTrips({imei,date});
  }
  
  handleOnTripClick = (tripDetails) =>{
  	 let allData = tripDetails.gps;
  	let tripStartPoints = [];
  	let tripMarkers = [];
  	
  	for(let i=0; i<allData.length; i++)
    {
    	if(allData[i].gps.latitude != 0 && allData[i].gps.longitude != 0)
    	{
		  	tripStartPoints.push({
		    	'lat' : allData[i].gps.latitude,
		    	'lng' : allData[i].gps.longitude,
		    })
    	}
    }
    
	let tripCenter = tripStartPoints[Math.floor(tripStartPoints.length/2)];
    tripMarkers.push( tripStartPoints[0] );
    tripMarkers.push( tripStartPoints[tripStartPoints.length-1] )
    
    this.setState({tripStartPoints,tripCenter,tripMarkers})
  }

  componentWillUnmount() {
    this.isUnmounted = true;
     clearInterval(this.state.intervalId)
  }
	
  
  ShowDetailsMap = () => {
    const { vehicleInfo, markers } = this.state;
    
    return <div className="gx-chat-main" style={{height:'100%',width:'100%'}}>
			<LoadMap  key={2}>
		  		   <GoogleMap
			        mapContainerStyle={mapContainerStyle}
			        center={markers}
			        zoom={15}
			      >
			    	<Marker icon={require(`assets/images/${vehicleInfo.ignition == 1 ? 'marker-green.png' :'marker-red.png'}`)}  key={'Key'}  position={markers} />
			      </GoogleMap>
		      </LoadMap>	
		</div>
  };
  
  ShowAllTripsMap = () => {
  	
  	const {allTrips,tripStartPoints,tripCenter,tripMarkers} = this.state;
  	return <div className="gx-chat-main" style={{height:'100%',width:'100%'}}>
  		<LoadMap key={1}>
	        <GoogleMap
				    mapContainerStyle={mapContainerStyle}
				    center={tripCenter}
				    zoom={15}
				    onLoad={map => {
					    const bounds = new window.google.maps.LatLngBounds();
					    for(let i=0 ; i < tripStartPoints.length ; i++ )
					    	bounds.extend(tripStartPoints[i]);

					    map.fitBounds(bounds);
					  }}
				>
	           <Polyline
			      path={tripStartPoints}
			      options={options}
			    />
			     
			      { 
			        tripMarkers.map((marker,index) => (
			          <Marker icon={marker.icon} key={index} position={marker} />
			        ))
			      }
			     
	        </GoogleMap>
      </LoadMap>
  	</div>
  }
  
  AppUsersInfo = () => {
    return <div className="gx-chat-sidenav-main">
       
    </div>
  };
  
  DeviceDetails = () => {
  	const {loading,markers,imei,vehicleInfo,allTrips,currentLocation} = this.state;
  	let {ignition,make,model,reg_no,last_update,speed,exVoltage,totalDistance} = vehicleInfo;
  	
  	if( loading )
  		return <div className="gx-loader-view"><CircularProgress /></div>
  	
    return <div className="gx-chat-sidenav-main">
	      <div className="gx-chat-sidenav-header">
	        <div className="gx-chat-user-hd gx-mb-0">
	          <div className="gx-chat-avatar gx-mr-3">
	            <div className="gx-status-pos">
	              <Avatar id="avatar-button" src={"https://via.placeholder.com/150"}
	                className="gx-size-50"
	                alt="" />
	              <span className={`gx-status ${ignition  ? `gx-online` : ` gx-away`}`}></span>
	            </div>
	          </div>
	
	          <div className="gx-module-user-info gx-flex-column gx-justify-content-center">
	            <div className="gx-module-title">
	              <h5 className="gx-mb-0">{reg_no}</h5>
	              <h5 className="gx-mb-0">{make+' '+model}</h5>
	            </div>
	            <div className="gx-module-user-detail">
	              <span className="gx-text-grey gx-link">Update time: {moment(last_update).format('MMMM Do YYYY, h:mm:ss a')}</span>
	            </div>
	          </div>
	        </div>
	      </div>

      <div className="gx-chat-sidenav-content">
        
        <Tabs  defaultActiveKey="1" className="gx-ml-3"  onChange={this.handleChangeIndex}>
          <TabPane  tab="Details" key="1">
            <CustomScrollbars className="gx-chat-sidenav-scroll-tab-1">
            <Row className="gx-mb-3">
		    	<Col md={12}>
		    		<div className="gx-module-title">
		    			<div className="gx-module-user-detail"><span className="gx-text-grey gx-link">ENGINE</span></div>
	            		<h5 className="gx-mb-0"><span style={{color: ignition == 1 ?  '#52c41a' : '#CC0000'}} >{ignition == 1 ?  'ON' : 'OFF'}</span></h5>
			         </div>
		    	</Col>
		    	<Col md={12}>
		    		<div className="gx-module-title">
		    			<div className="gx-module-user-detail"><span className="gx-text-grey gx-link">DISTANCE</span></div>
	            		<h5 className="gx-mb-0">{totalDistance} Km</h5>
			         </div>
		    	</Col>
		      </Row>
		      <Row className="gx-mb-3">
		    	<Col md={12}>
		    		<div className="gx-module-title">
		    			<div className="gx-module-user-detail"><span className="gx-text-grey gx-link">BATTERY</span></div>
	            		<h5 className="gx-mb-0">{exVoltage} V</h5>
			         </div>
		    	</Col>
		    	<Col md={12}>
		    		<div className="gx-module-title">
		    			<div className="gx-module-user-detail"><span className="gx-text-grey gx-link">SPEED</span></div>
	            		<h5 className="gx-mb-0">{speed} kph</h5>
			         </div>
		    	</Col>
		      </Row>
		      
		      <Row>
			      <Col md={24}>
				    	<span className="gx-text-grey gx-link">Location</span>
						<h5 className="gx-mb-0 gx-module-title">{currentLocation}</h5>
					</Col>
	           </Row>
            </CustomScrollbars>
          </TabPane>
          <TabPane  tab="Trips" key="2">
          <DatePicker onChange={this.handleDateChange} defaultValue={moment(new Date(), dateFormat)} format={dateFormat} style={{width:'100%'}}/>
            <CustomScrollbars className="gx-chat-sidenav-scroll-tab-2">
              {
                allTrips.length === 0 ?
                  <div className="gx-p-5">Trips Not Found!</div>
                  :
	        		<Row style={{margin:8}}>
					{
					allTrips.map((item,index) =>{
				    return <Card style={{width:'100%'}} title={`Trip#${index+1}`} extra={<Button onClick={() => this.handleOnTripClick(item)} type="link">Show</Button>}  key={index}>
						      <Row>
						    	<Col md={12}>
						    		<div className="gx-module-title">
						    			<div className="gx-module-user-detail"><span className="gx-text-grey gx-link">START TIME</span></div>
					            		<h5 className="gx-mb-0">{moment(item.started_at).format('hh:mm A')}</h5>
							         </div>
						    	</Col>
						    	<Col md={12}>
						    		<div className="gx-module-title">
						    			<div className="gx-module-user-detail"><span className="gx-text-grey gx-link">END TIME</span></div>
					            		<h5 className="gx-mb-0">{moment(item.ended_at).format('hh:mm A')}</h5>
							         </div>
						    	</Col>
						      </Row>
						      <Row>
						    	<Col md={12}>
						    		<div className="gx-module-title">
						    			<div className="gx-module-user-detail"><span className="gx-text-grey gx-link">DISTANCE</span></div>
					            		<h5 className="gx-mb-0">{(item.distance/1000).toFixed(2)} km</h5>
							         </div>
						    	</Col>
						    	<Col md={12}>
						    		<div className="gx-module-title">
						    			<div className="gx-module-user-detail"><span className="gx-text-grey gx-link">TRIP DURATION</span></div>
					            		<h5 className="gx-mb-0">{timeDiffrence(item.started_at,item.ended_at)}</h5>
							         </div>
						    	</Col>
						      </Row>
						    </Card>
						})
					}
				</Row>
              }
            </CustomScrollbars>
          </TabPane>
          <TabPane tab="Summary" key="3">
            <CustomScrollbars className="gx-chat-sidenav-scroll-tab-2">
              No Data Found
            </CustomScrollbars>
          </TabPane>
        </Tabs>


      </div>
    </div>
  };
  

  handleChangeIndex = index => {
    this.setState({ selectedTabIndex: index });
  };

  
  ShowMap = () => {
  	const {selectedTabIndex} = this.state;
  	
  	if(selectedTabIndex == 1)
  		return this.ShowDetailsMap();
  	else if(selectedTabIndex == 2)
  		return this.ShowAllTripsMap();
  	else
  		return null;
     
  };

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState
    });
  }

  render() {
    const { loading, drawerState } = this.state;
    return (
      <div className="gx-main-content">
        <div className="gx-app-module gx-chat-module">
          <div className="gx-chat-module-box">
            <div className="gx-d-block gx-d-lg-none">
              <Drawer
                placement="left"
                closable={false}
                visible={drawerState}
                onClose={this.onToggleDrawer.bind(this)}>
                {this.AppUsersInfo()}
              </Drawer>
            </div>
            <div className="gx-chat-sidenav gx-d-none gx-d-lg-flex">
              { this.DeviceDetails() }
            </div>
            {loading ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> : this.ShowMap()
            }
          </div>
        </div>
      </div>
    )
  }
}


export default New_Device_Detail;
