import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import AllPlan from './all';
import UpdatePlan from './Update';
import History from './history';

const PlanRoute = ({match}) => (
  <Switch>
	<Route path={`${match.url}/history`}  component={History} />
	<Route path={`${match.url}/update/:planid`}  component={UpdatePlan} />
	<Route path={`${match.url}`}		component={AllPlan} />
  </Switch>
);

export default PlanRoute;
