import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Card, Checkbox, Form, Input, message,Select,Col} from "antd";
import {UnorderedListOutlined } from '@ant-design/icons';
import axios from 'axiosConfig';

const FormItem = Form.Item;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

class AddSim extends Component {
	
	 formRef = React.createRef();
	 
	constructor() {
	    super();
	    this.state = {
		 btnLoading:false,
	    };
	  }
	  
	 componentDidMount() {
	 	
	 }
	 
	  onFinish = (values) => {
	  	this.setState({btnLoading:true});
		axios.post('sims',values)
		.then((response) => {
			let data = response.data;
			if(data.success)
			{
				message.success(data.message);
				this.formRef.current.resetFields();
			}
			else
			{
				message.error(data.message);
			}
			
			this.setState({btnLoading:false});
	  })
	  .catch((error) => {
	  	let resp = error.response
	  	if(resp.status == 422)
	  	{
	  		let data = resp.data;
	  		
	  		Object.keys(data).map((item)=>{
	  			 message.error(data[item][0]);
	  		})
	  		
	  	}
	  	else
	  	{
	    	message.error('Something went wrong! Please try again');
	  	}
	    this.setState({btnLoading:false});
	  })
	 
	 }
	 
	 
	 onReset = () => {
		    this.formRef.current.resetFields();
	 };
	 
	 render() {
	 	const {loading,btnLoading} = this.state;
	    return (
	    	<Card className="gx-card" title="Add New Sim" extra={<Link to="/main/sim"> <Button type="primary"icon={<UnorderedListOutlined />}>All Sim</Button></Link>}>
	    		<Col xs={24} xl={12} md={12} lg={12} sm={24}>
	    		<Form
					{...layout}
					name="add_sim"
					ref={this.formRef} 
					onFinish={this.onFinish}
					colon={false}					
				>
			          
		           <Form.Item name="provider" label="Provider" rules={[{ required: true }]}>
			        <Select
			          placeholder="Select Sim Provider"
			          allowClear
			        >
			          <Option value="airtel">Airtel</Option>
			          <Option value="vodafone">Vodafone</Option>
			          <Option value="jio">Jio</Option>
			        </Select>
			      </Form.Item>
		          <FormItem
		        	label="Sim ID"
        			rules={[{ required: true, message: 'Please input Sim Serial Number!' }]}
                    name="sim_id"
		           >
		            <Input placeholder="Sim Serial Number"/>
		          </FormItem>
		          
		          <FormItem
		        	label="Number"
        			rules={[{ required: true, message: 'Please input 10 digit Sim Number!' }]}
                    name="sim_number"
		           >
		            <Input placeholder="10 digit Number"/>
		          </FormItem>
		          
		          <FormItem
		        	label="IMSI"
        		//	rules={[{ message: 'Please input 10 digit Sim Number!' }]}
                    name="imsi"
		           >
		            <Input placeholder="IMSI"/>
		          </FormItem>
			          
		          <FormItem label="&nbsp;">
		            <Button type="primary" htmlType="submit" loading={btnLoading}>
		              Save
		            </Button>
		             <Button htmlType="button" onClick={this.onReset} >
			            Reset
			          </Button>
		          </FormItem>
			      </Form>
			      </Col>
	    	</Card>
	    );
  }
}


export default (AddSim);
