import React, {Component} from "react";
import {Link} from "react-router-dom";
import fetch from "isomorphic-fetch";
import {Row,Col,Card,Tabs,Radio,Button,DatePicker,Spin } from "antd";
import { GoogleMap, LoadScript,InfoWindow,Polyline,MarkerClusterer, Marker  } from '@react-google-maps/api';
import axios from 'axiosConfig';
import { SyncOutlined,PoweroffOutlined} from '@ant-design/icons';
import moment from 'moment';
import LoadMap from './LoadMap';

const google = window.google;

const TabPane = Tabs.TabPane;
const dateFormat = 'MMMM Do YYYY';

const mapContainerStyle = {
  height: "100%",
  width: "100%"
};

const center = {
  lat: 0,
  lng: -180
}

const position = {
  lat: 37.772,
  lng: -122.214
}


const markeroptions = {
  imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
};


export default class DeviceDetails extends Component {
	
  isUnmounted :false;
  constructor() {
    super();
    this.state = {
		intervalId: null,
		imei:null,
		markers: {},
		vehicleInfo:{},
		loading:true,
    };
  }

  componentDidMount() {
  	const { imei } = this.props.match.params;
  	this.setState({imei},() => {
  		this.getDeviceLocation();
  	})
  	
  	this.state.intervalId = setInterval(()=>{
      this.getDeviceLocation();
    }, 30000);
  }
  
  getDeviceLocation() {
  	const {imei} = this.state;
    axios.get('get-device-locations',{params:{imei}})
      .then((response) => {
        let alldata 	= response.data;
        let gpsdata 	= alldata.data.gps;
        let ioElements	= alldata.data.ioElements;
        
        let vehicleInfo = {};
        vehicleInfo.imei 	= alldata.imei;
        vehicleInfo.make 	= alldata.make;
        vehicleInfo.model 	= alldata.model;
        vehicleInfo.reg_no 	= alldata.reg_no;
        vehicleInfo.last_update 	= alldata.data.timestamp;
        vehicleInfo.speed			=	gpsdata.speed;
       
        
        ioElements.forEach((ioitem) => {
        	if(ioitem.id == 239)
        		vehicleInfo.ignition = ioitem.value;
        	if(ioitem.id == 66)
        	 vehicleInfo.exVoltage		=	(ioitem.value/1000).toFixed(2);
        	 if(ioitem.id == 16)
        		vehicleInfo.totalDistance =	(ioitem.value/1000).toFixed(2);
        })
        
	    let  markers = {lat:gpsdata.latitude, lng:gpsdata.longitude}
        
        this.setState({markers,vehicleInfo,loading:false})
        
      })
      .catch((error) => {
        console.log(error);
      })
  }

  componentWillUnmount() {
    this.isUnmounted = true;
     clearInterval(this.state.intervalId)
  }
  
 
  
  render() {
  	const {loading,markers,imei,vehicleInfo} = this.state;
  	
  	let {ignition,make,model,reg_no,last_update,speed,exVoltage,totalDistance} = vehicleInfo;
  	
  	if( loading )
  		return <Spin indicator={<SyncOutlined style={{fontSize:30}} spin />} />;
  	
   
    return (
    		<Row style={{height:'100%',width:'100%',background:'#fff'}}>
    			<Col xl={8} md={8} sm={8} xs={24} style={{padding:0}}>
			    <div className="gx-chat-sidenav-header">
				   <div className="gx-chat-user-hd" style={{marginBottom:0}}>
				      <div className="gx-chat-avatar gx-mr-3">
				         <div className="gx-status-pos">
				            <span id="avatar-button" className="ant-avatar gx-size-50 ant-avatar-circle ant-avatar-image">
				            <img src="https://via.placeholder.com/150" alt=""/>
				            </span><span className={`gx-status ${ignition  ? `gx-online` : ` gx-away`}`}></span>
				         </div>
				      </div>
				      <div className="gx-module-user-info gx-flex-column gx-justify-content-center">
				         <div className="gx-module-title">
				            <h5 className="gx-mb-0">{reg_no}</h5>
				            <h6 className="gx-mb-0">{make+' '+model}</h6>
				         </div>
				         <div className="gx-module-user-detail"><span className="gx-text-grey gx-link">Update time: {moment(last_update).format('MMMM Do YYYY, h:mm:ss a')}</span></div>
				      </div>
				   </div>
				</div>
				<Row style={{margin:0,borderBottom:'1px solid #eee'}}>
					<Col md={24} style={{margin:8}}>
						<Link to={`/main/live-tracking/${imei}`}><Button  type="primary">Details</Button></Link>
						<Link to={`/main/live-tracking/${imei}/trips`}><Button type="link">Trips</Button></Link>
						<Link to={`/main/live-tracking/${imei}/summary`}><Button type="link">Summary</Button></Link>	
					</Col>
				</Row>
				<Row style={{margin:8,borderBottom:'1px solid #eee'}}>
					
					<Row style={{marginBottom:8}}>
						<Col md={24}>
							<h5 className="gx-mb-1 gx-module-title">Vehicle Stats</h5>
						</Col>
						<Col md={12}>
					     <div>
					    	<span className="gx-text-grey gx-link"><PoweroffOutlined style={{color: ignition == 1 ?  '#52c41a' : '#CC0000'}} /> </span>
					    	<span style={{color: ignition == 1 ?  '#52c41a' : '#CC0000'}} >{ignition == 1 ?  'ON' : 'OFF'}</span>
					     </div>
					    </Col>
					    <Col md={12}>
					     <div>
					    	<span className="gx-text-grey gx-link">Run </span>
					    	<span className="text-danger">{totalDistance} Kms</span>
					     </div>
					    </Col>
				 </Row>
				 
				 <Row style={{marginBottom:8}}>
						<Col md={12}>
					     <div tooltip="Battery">
					    	<span className="gx-text-grey gx-link"><svg height="28" viewBox="0 0 512 512" width="28" xmlns="http://www.w3.org/2000/svg"><g><path d="m354 64h-18v-28c0-19.85-16.15-36-36-36h-88c-19.85 0-36 16.15-36 36v28h-18c-25.364 0-46 20.636-46 46v356c0 25.364 20.636 46 46 46h196c25.364 0 46-20.636 46-46v-356c0-25.364-20.636-46-46-46zm-34 392h-128c-8.837 0-16-7.164-16-16s7.163-16 16-16h128c8.837 0 16 7.164 16 16s-7.163 16-16 16zm0-64h-128c-8.837 0-16-7.164-16-16s7.163-16 16-16h128c8.837 0 16 7.164 16 16s-7.163 16-16 16zm0-64h-128c-8.837 0-16-7.164-16-16s7.163-16 16-16h128c8.837 0 16 7.164 16 16s-7.163 16-16 16z"/></g></svg></span>
					    	<span className="text-danger"> {exVoltage} V</span>
					     </div>
					    </Col>
					    <Col md={12}>
					     <div>
					    	<span className="gx-text-grey gx-link">Speed</span>
					    	<span className="text-danger">{speed} kph</span>
					     </div>
					    </Col>
					    
				    </Row>
				    <Row>
				    	<Col md={24}>
					    	<span className="gx-text-grey gx-link">Location</span>
							<h5 className="gx-mb-0 gx-module-title">pattawli maszed 9813447663, Baghola, Haryana 122104, India</h5>
						</Col>
				    </Row>
				    
				</Row>
				</Col>
				<Col  xl={16} md={16} sm={16} xs={24} style={{padding:0}}>
		        	<LoadMap>
			  		   <GoogleMap
				        mapContainerStyle={mapContainerStyle}
				        center={markers}
				        zoom={18}
				      >
				    	<Marker icon={require(`assets/images/${vehicleInfo.ignition == 1 ? 'marker-green.png' :'marker-red.png'}`)}  key={'Key'}  position={markers} />
				      </GoogleMap>
			      </LoadMap>
		      </Col>
    		</Row>
       
    );
  }
}
