import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import AddUser from "./Add";
import AllUsers from "./All";

const DevicesRoute = ({match}) => (
  <Switch>
    <Route path={`${match.url}/add`} component={AddUser}/>
    <Route path={`${match.url}`}	component={AllUsers}/>
  </Switch>
);

export default DevicesRoute;
