import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import AddDevice from "./Add";
import AllDevices from "./All";
import UpdateDevice from "./Update";

const DevicesRoute = ({match}) => (
  <Switch>
	<Route path={`${match.url}/update/:deviceId`} component={UpdateDevice}/>
    <Route path={`${match.url}/add`} component={AddDevice}/>
    <Route path={`${match.url}`}	component={AllDevices}/>
  </Switch>
);

export default DevicesRoute;
