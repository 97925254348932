import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Card,Tag, Table,Button} from "antd";
import moment from 'moment';
import axios from 'axiosConfig';
import {PlusOutlined  }  from '@ant-design/icons';

class AllCategoryBrands extends Component {
	
	constructor() {
	    super();
	    this.state = {
		 loading:true,
		 category_id:0,
		 pagination :{ current:1,defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '50','100','250']},
		 brands:[],
		 data:[],
		 title:''
	    };
	  }
	  
	 componentDidMount() {
	 	const {category_id} = this.props.match.params;
	 	
	 	 this.getData({category_id});
	 }
	 
	getData = (params = {}) =>{
		this.setState({loading:true})
		const {pagination} =  this.state ;
		axios.get('brands',{params:params})
		.then((response) => {
			let resp = response.data;
			let data = resp.data;

			pagination.total = data.total;
			pagination.current = data.current_page;
			
			console.log(data.data[0])
			
			if( data.data.length > 0 )
				this.setState( { title : data.data[0].category.title } );
			
				 
			this.setState({loading:false,data})
		})
		.catch((error) => {
		console.log(error);
		})
	}
	
	 
	render () {
		
		const {loading,data,title} = this.state;
		
		const columns = [{
			  title: 'Id',
			  dataIndex: 'id',
			  width: 150,
			}
			,{
			  title: 'Name',
			  dataIndex: 'title',
			  width: 150,
			},{
			  key:'id',
			  title: 'List',
			  width: 150,
			   render : (row) => (<Link to={`/main/vehicles/all-models/${row.category_id}/${row.id}`}><Tag color={'#87d068'}>Models</Tag></Link>)
			}];
		if(loading)
			return null;

		return (
		
			<Card 
				className="gx-card"
				title={`All ${title} Brands`}
				extra={
				<>
					<Link to="/main/vehicles/add-make"><Button type="primary" icon={<PlusOutlined/>}>Make</Button></Link>
				</>	
				}
			>
		      <Table size={'small'} 
			      loading={loading} 
			      className="gx-table-responsive" 
			      columns={columns} 
			      dataSource={data.data} 
			      pagination={false}
			      scroll={{y: 240}}
		      />
		    </Card>
		)
	}
	
}

export default AllCategoryBrands;
