import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Card, Tag, Table, Button, Tooltip, message } from "antd"
import moment from "moment"
import axios from "axiosConfig"
import { UserAddOutlined } from "@ant-design/icons"

class AllClients extends Component {
	constructor() {
		super()
		this.state = {
			loading: true,
			data: [],
			pagination: {
				defaultPageSize: 10,
				showSizeChanger: true,
				pageSizeOptions: ["10", "20", "30"],
			},
		}
	}

	componentDidMount() {
		this.getData()
	}

	handleTableChange = (pagination, filters, sorter) => {
		const pager = { ...this.state.pagination }
		pager.current = pagination.current
		this.getData(pagination, filters, sorter)
	}

	handleChnageStatus = (id, status) => {
		axios.post("update-user", { id, status })
			.then((response) => {
				let data = response.data

				if (data.success) message.success(data.message)
				else message.error(data.message)

				this.getData()
			})
			.catch((error) => {
				message.error("Error! Please Try again")
			})
	}

	getData = (params = {}) => {
		this.setState({ loading: true })
		const { pagination } = this.state

		axios.get("customers", { params: params })
			.then((response) => {
				let resp = response.data;
				let data = resp.data;
				pagination.total	= data.total;
				pagination.current	= data.current_page;

				this.setState({ loading: false, data, pagination })
			})
			.catch((error) => {
				console.log(error)
			})
	}
	
	renderDevices = ( devices ) => {
		
		const deviceColumns = [
		{
			
		  title: 'Id',
		  dataIndex: 'deviceid',
		  key:'deviceid'
		},{
			
		  title: 'Vehicle',
		  dataIndex: 'make',
		  width: 150,
		  render:(text,row) => <span>{`${row.make} ${row.model}`}</span>
		},{
		  title: 'IMEI',
		  dataIndex: 'imei',
		},{
		  title: 'Sim Id',
		  dataIndex: 'sim_id',
		}, {
		  title: 'Sim Number',
		  dataIndex: 'sim_number',
		},
		{
		  title: 'Valid Till',
		  dataIndex: 'valid_till',
		  render: text => text == 'Expired' ? <Tag color={"#f50"} key={text}>{text}</Tag> : moment(text).format("DD MMM YY")
		}
		,{
			title: 'Date',
			dataIndex: 'installdate',
			render: text => moment(text).format("DD MMM YY") ,
		},
		{
			key:"Edit",
			title: "Edit",
			dataIndex: 'deviceid',
			render: (text) => <Link to={`/main/devices/update/${text}`}>Edit</Link>
		}
		];
		
		return <Table
			size={"small"}
			className="gx-table-responsive"
			columns={deviceColumns}
			dataSource={devices}
			pagination={false}
		/>
	}

	render() {
		const { loading, data, pagination } = this.state
		const columns = [
			{
				key:"Id",
				title: "Id",
				dataIndex: "id",
				width: 50,
			},
			{
				key:"Name",
				title: "Name",
				dataIndex: "name",
				width: 150,
			},
			{
				key:"Mobile",
				title: "Mobile",
				dataIndex: "phone",
				width: 150,
			},
			{
				key:"Email",
				title: "Email",
				dataIndex: "email",
			},
			{
				key:"Status",
				title: "Status",
				dataIndex: "status",
				render: (status) => (
					<Tag color={status == "Active" ? "#87d068" : "#f50"} key={status}>
						{" "}
						{status.toUpperCase()}
					</Tag>
				),
			},
			{
				key:"Date",
				title: "Date",
				dataIndex: "createdAt",
				render: (text) => moment(text).format("D MMM, YYYY"),
			}
		]

		return (
			<Card
				className="gx-card"
				title={`All Users ( ${data.total} )`}
				extra={
					<>
						<Link to="/main/clients/add">
							<Button type="primary" icon={<UserAddOutlined />}>
								{" "}
								New Client
							</Button>
						</Link>{" "}
						&nbsp;&nbsp;
						<Link to="/main/clients/append">
							<Button type="primary" icon={<UserAddOutlined />}>
								{" "}
								Existing Client
							</Button>
						</Link>
					</>
				}
			>
				<Table
					size={"small"}
					loading={loading}
					onChange={this.handleTableChange}
					className="gx-table-responsive"
					columns={columns}
					dataSource={data.data}
					pagination={pagination}
					rowKey="phone" 
					expandable={{
						expandedRowRender:  (record) => this.renderDevices(record.devices),
				    }}
				/>
			</Card>
		)
	}
}

export default AllClients
