import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Card, Checkbox, Form, Input, message,Select,Upload,Col} from "antd";
import {UnorderedListOutlined } from '@ant-design/icons';
import { UploadOutlined, InboxOutlined,LoadingOutlined,PlusOutlined } from '@ant-design/icons';
import axios from 'axiosConfig';

const FormItem = Form.Item;
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

class AddCategory extends Component {
	
	 formRef = React.createRef();
	 
	constructor() {
	    super();
	    this.state = {
			loading:false,
			btnLoading:false,
			imageUploading:false,
			image_name : null,
	    };
	  }
	  
	 componentDidMount() {
	 	 
	 }
	 
	 
	  onFinish = (values) => {
	  	this.setState({btnLoading:true});
	  	values.image_name = this.state.image_name;
		axios.post('categories',values)
		.then((response) => {
			let data = response.data;
			if(data.success)
			{
				message.success(data.message);
				this.formRef.current.resetFields();
			}
			else
				message.error(data.message);
			
			this.setState({btnLoading:false});
	  })
	  .catch((error) => {
	  	let resp = error.response
	  	if(resp.status == 422)
	  	{
	  		let data = resp.data;
	  		
	  		Object.keys(data).map((item)=>{
	  			 message.error(data[item][0]);
	  		})
	  	}
	  	else
	    	message.error('Something went wrong! Please try again');
	    	
	    this.setState({btnLoading:false});
	  })
	 
	 }
	 
	 
	 onReset = () => {
		    this.formRef.current.resetFields();
	 };
	 
	 handleImageChange = info => {
	    if (info.file.status === 'uploading') {
	      this.setState({ imageUploading: true });
	      return;
	    }
	    if (info.file.status === 'done') {
	      var {image_name} = info.fileList[0].response;
	        this.setState({ image_name, imageUploading: false})
	    }
	};
	 
	 render() {
	 	const {loading,btnLoading,image_name} = this.state;
	 	
	  
	 	 const uploadButton = (
	      <div>
	        {this.state.imageUploading ? <LoadingOutlined /> : <PlusOutlined />}
	        <div className="ant-upload-text">Upload</div>
	      </div>
	    );
	 		
	    return (
	    	<Card className="gx-card" title="Add New Category" extra={<Link to="/main/vehicles/categories"> <Button type="primary" icon={<UnorderedListOutlined/>}>All Categories</Button></Link>}>
	    		<Col xs={24} xl={12} md={12} lg={12} sm={24}>
	    		<Form
	    			colon={false}
					{...layout}
					name="add_category"
					ref={this.formRef} 
					initialValues={{ remember: true }}
					onFinish={this.onFinish}
				>
			          
			          <FormItem
			        	label="Name"
	        			rules={[{ required: true, message: 'Please Enter Category Name!' }]}
	                    name="title"
			           >
			            <Input placeholder="Enter Category Name"/>
			          </FormItem>
				      
			           <FormItem label="Upload Image">
				          <Upload 
				        	name="category_icon"
				        	listType="picture-card"
	        				className="avatar-uploader"
	        				showUploadList={false}
	        				action="/api-v1/upload-file"
	        				beforeUpload={beforeUpload}
	        				onChange={this.handleImageChange}
				          >
						    {image_name ? <img src={`https://track.gopista.com/images/${image_name}`} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
						  </Upload>
					  </FormItem>
				      
			          <FormItem label="&nbsp;">
			            <Button type="primary" htmlType="submit" className="login-form-button gx-mt-1" loading={btnLoading}>
			              Save
			            </Button>
			          </FormItem>
			        </Form>
			      </Col>
	    	</Card>
	    );
  }
}


export default (AddCategory);
