import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Card,Tag, Table,Button} from "antd";
import moment from 'moment';
import axios from 'axiosConfig';

class AllModels extends Component {
	
	constructor() {
	    super();
	    this.state = {
		 loading:true,
		 tblLoading:true,
		 category_id	:0,
		 maker_id	:	0,
		 pagination :{ current:1,defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '50','100','250']},
		 data:[],
		 title:''
	    };
	  }
	  
	 componentDidMount() {
	 	const {maker_id,category_id} = this.props.match.params;
	 	
	 	this.setState({maker_id,category_id},() =>{
	 		this.getData({category_id,maker_id});
	 	})
	 	 
	 }
	 
	getData = (params = {}) =>{
		this.setState({tblLoading:true})
		const {pagination,category_id,maker_id} =  this.state ;
		params.category_id = category_id;
		params.maker_id = maker_id;
		axios.get('models',{params:params})
		.then((response) => {
			let resp = response.data;
			let data = resp.data;
			pagination.total = data.total;
			pagination.current = data.current_page;
			 
			if( data.data.length > 0 )
				this.setState( { title : data.data[0].brand.title } );
			
				
			this.setState({tblLoading:false,loading:false,data})
		})
		.catch((error) => {
			console.log(error);
		})
	}
	
	handleTableChange = (pagination, filters, sorter) => {
		const pager = { ...this.state.pagination };
		pager.current = pagination.current;
		this.getData(pagination,filters,sorter)
		 
	};
	
	 
	render () {
		const {loading,tblLoading,data,pagination,title} = this.state;
		
		const columns = [{
			  title: 'Id',
			  dataIndex: 'id',
			  width: 150,
			}
			,{
			  title: 'Name',
			  dataIndex: 'title',
			  width: 150,
			},{
			  title: 'Image',
			  dataIndex: 'image',
			  render : (image,row) => row.hasOwnProperty('image') &&  image != null ?  <span> <img width="80" src={`https://track.gopista.com/images/${image}`} /> </span> : 'No Image',
			  width: 150,
			}
			,{
			  title: 'Actions',
			  dataIndex: '',
			  render : (row) => (<Link to={`/main/vehicles/update-model/${row.id}`}>Edit</Link>),
			  width: 150,
			}];
			
		if(loading)
			return null;

		return (
		
			<Card 
				title={`All ${title} Models`}
				extra={
				<>
					<Link to="/main/vehicles/add-model"><Button type="primary"icon={<i className="icon icon-add"/>}>New Model</Button></Link>
				</>	
				}
			>
		      <Table size={'small'} 
			      loading={tblLoading} 
			      className="gx-table-responsive" 
			      columns={columns} 
			      onChange={this.handleTableChange} 
			      dataSource={data.data} 
			      pagination={pagination}
			      scroll={{y: 240}}
		      />
		    </Card>
		)
	}
	
}

export default AllModels;
