import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Card, Checkbox, Form, Input, message,Select,Col} from "antd";
import {TeamOutlined }  from '@ant-design/icons';
import axios from 'axiosConfig';

const FormItem = Form.Item;
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class AddUser extends Component {
	
	 formRef = React.createRef();
	 
	constructor() {
	    super();
	    this.state = {
			loading:false,
			btnLoading:false,
			roles : [],
	    };
	  }
	  
	 componentDidMount() {
	  
	 }
	 
	  onFinish = (values) => {
	  	this.setState({btnLoading:true});
		axios.post('users',values)
		.then((response) => {
			let data = response.data;
			if(data.success)
			{
				message.success(data.message);
				this.formRef.current.resetFields();
			}
			else
				message.error(data.message);
			
			this.setState({btnLoading:false});
	  })
	  .catch((error) => {
	    message.error('Something went wrong! Please try again');
	    this.setState({btnLoading:false});
	  })
	 
	 }
	 
	 
	 onReset = () => {
		    this.formRef.current.resetFields();
	 };
	 
	 render() {
	 	const {loading,btnLoading,roles} = this.state;
	 	
	 	if(loading)
	 		return null;
	 		
	    return (
	    	<Card className="gx-card" title="Add New User" extra={<Link to="/main/users"> <Button type="primary"icon={<TeamOutlined  />}>All Users</Button></Link>}>
	    		<Col xs={24} xl={12} md={12} lg={12} sm={24}>
	    		<Form
					{...layout}
					name="add_user"
					ref={this.formRef} 
					colon={false}
					onFinish={this.onFinish}
				>
			          
			          <FormItem
			        	label="Name"
	        			rules={[{ required: true, message: 'Please Enter Full Name!' }]}
	                    name="name"
			           >
			            <Input placeholder="Enter Full Name"/>
			          </FormItem>
			          
			          <FormItem
			        	label="Mobile"
	        			rules={[{ required: true, message: 'Please Enter Primary Contact NumberI!' }]}
	                    name="phone"
			           >
			            <Input min={10} max={10} placeholder="Enter Primary Contact Number"/>
			          </FormItem>
			          
			          <FormItem
			        	label="Email"
	        		//	rules={[{ required: true, message: 'Please Email address!' }]}
	                    name="email"
			           >
			            <Input placeholder="Enter Email address"/>
			          </FormItem>
			          
			           <FormItem
			        	label="Password"
	        			rules={[{ required: true,message: 'Please Email address!' }]}
	                    name="password"
			           >
			            <Input min={6} placeholder="Enter Secure password"/>
			          </FormItem>
			          
			          <Form.Item name="role" label="Type/Role" rules={[{ required: true }]}>
				        <Select
				          placeholder="Select User Type/Role"
				          allowClear
				        >
					        <Option key={'installer'} value={'installer'}>Installer</Option>
					        <Option key={'operator'} value={'operator'}>Operator</Option>
				        </Select>
				      </Form.Item>
			          
			          <FormItem label="&nbsp;">
			            <Button type="primary" htmlType="submit" loading={btnLoading}>
			              Save
			            </Button>
			             <Button htmlType="button" onClick={this.onReset} >
				            Reset
				          </Button>
			          </FormItem>
			        </Form>
			        </Col>
	    	</Card>
	    );
  }
}


export default (AddUser);
