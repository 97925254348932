import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Card,Tag,Table,Button,Row,Col,Input,message,Upload} from "antd";
import moment from 'moment';
import axios from 'axiosConfig';
import {PlusOutlined , UploadOutlined ,ScheduleOutlined }  from '@ant-design/icons';

class AllPlan extends Component {
	
	constructor() {
	    super();
	    this.state = { 
		 loading:true,
		 searchTerm:'',
		 data:[],
		 fileList: [],
		 pagination :{ current:1,defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '50','100','250']}
	    };
	  }
	  
	 componentDidMount() {
	 	const {pagination} = this.state;
	 	let pageSize: pagination.defaultPageSize;
	 	let current	: pagination.current;
	 	 this.getData({current,pageSize});
	 }
	 
	handleTableChange = (pagination, filters, sorter) => {
		const pager = { ...this.state.pagination };
		pager.current = pagination.current;
		this.getData(pagination,filters,sorter)
		 
	};
	
	getData = (params = {}) => {
		
		this.setState({loading:true});
		
		const {pagination} =  this.state ;
		axios.get('plans',{params:params})
		.then((response) => {
			
			let data = response.data;
		 
			pagination.total = data.total;
			pagination.current = data.current_page;
			
			this.setState({loading:false,data,pagination})
			
		})
		.catch((error) => {
			console.log(error);
		})
	}
	
	handleSearch = () => {
		const {searchTerm } = this.state;
		this.getData({search:searchTerm})
	}
	
	handleReset = () =>{
		this.setState({searchTerm:''})
		this.getData()
	}
	
	handleUpload = () => {
		
	    const { fileList } = this.state;
	    const formData = new FormData();
	    
	    formData.append('device_csv_file', fileList[0]);
	     
	    this.setState({
	      uploading: true,
	    });
	
	    // You can use any AJAX library you like
	    axios.post('upload-devices-csv', formData, {
	        headers: {
	          'Content-Type': 'multipart/form-data'
	        }
	    })
	    .then((response) => {
			message.success('upload successfully.');
		})
		.catch((error) => {
			message.error('upload failed.');
		})
	    
	     
	  };
	 
	render () {
		const {loading,data,pagination} = this.state;
		let {fileList} = this.state;
		
		const columns = [
			{
			  title: 'Id', 
			  dataIndex: 'id',
			},
			{
			  title: 'Validity',
			  dataIndex: 'plan_days', 
			  width: 150,
			  render:(text,row) => <span>{text} days</span>
			},{
			  title: 'Price',
			  dataIndex: 'plan_price',
			},
			{
			  title: 'Discount',
			   dataIndex: 'plan_dis_price',
			},
			,{
			
			  title: 'Created',
			  dataIndex: 'created_at',
			  render: text => moment(text).format("D MMM YYYY"),
			}
			,{
				title: 'Updated',
				dataIndex: 'updated_at',
				render: text => moment(text).format("D MMM YYYY"),
			},
			{
			  title: 'Status',
			  dataIndex: 'plan_status',
			  render:(status) => (<Tag color={status == 1 ? '#87d068' : '#f50'} key={status}> {status ==1 ? 'Active' : 'Inactive'}</Tag>)
			},
			{
				key:"Edit",
				title: "Edit",
				dataIndex: 'id',
				render: (text) => <Link to={`/main/plan/update/${text}`}>Edit</Link>
			}
		];

		return (
			<Card 
				title={`All Plans ( ${data.total} )`}
				extra={<>
						<Link to="/main/plan/add"> 
							<Button type="primary"icon={<PlusOutlined/>}>New Plan</Button>
						</Link>
						&nbsp;&nbsp;
						<Link to="/main/plan/history"> 
							<Button type="primary"icon={<ScheduleOutlined/>}>Recharge History</Button>
						</Link>
					</>}
			>
		      <Table size={'small'} 
			      loading={loading} 
			      onChange={this.handleTableChange} 
			      className="gx-table-responsive" 
			      columns={columns} 
			      dataSource={data.data} 
			      pagination={pagination}
		      />
		    </Card>
		)
	}
	
}

export default AllPlan;
