import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Card,Tag,Table,Button,Row,Col,Input,message,Upload} from "antd";
import moment from 'moment';
import axios from 'axiosConfig';
import {PlusOutlined , UploadOutlined }  from '@ant-design/icons';

class AllDevices extends Component {
	
	constructor() {
	    super();
	    this.state = { 
		 loading:true,
		 searchTerm:'',
		 data:[],
		 fileList: [],
		 pagination :{ current:1,defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '50','100','250']}
	    };
	  }
	  
	 componentDidMount() {
	 	const {pagination} = this.state;
	 	let pageSize: pagination.defaultPageSize;
	 	let current	: pagination.current;
	 	 this.getData({current,pageSize});
	 }
	 
	handleTableChange = (pagination, filters, sorter) => {
		const pager = { ...this.state.pagination };
		pager.current = pagination.current;
		this.getData(pagination,filters,sorter)
		 
	};
	
	getData = (params = {}) =>
	{
		this.setState({loading:true})
		const {pagination} =  this.state ;
		axios.get('devices',{params:params})
		.then((response) => {
			let resp = response.data;
			let data = resp.data;
		 
			pagination.total = data.total;
			pagination.current = data.current_page;
			
			this.setState({loading:false,data,pagination})
		})
		.catch((error) => {
			console.log(error);
		})
	}
	
	handleSearch = () => {
		const {searchTerm } = this.state;
		this.getData({search:searchTerm})
	}
	
	handleReset = () =>{
		this.setState({searchTerm:''})
		this.getData()
	}
	
	handleUpload = () => {
		
	    const { fileList } = this.state;
	    const formData = new FormData();
	    
	     
	    formData.append('device_csv_file', fileList[0]);
	     
	    
	    this.setState({
	      uploading: true,
	    });
	
	    // You can use any AJAX library you like
	    axios.post('upload-devices-csv', formData, {
	        headers: {
	          'Content-Type': 'multipart/form-data'
	        }
	    })
	    .then((response) => {
			message.success('upload successfully.');
		})
		.catch((error) => {
			message.error('upload failed.');
		})
	    
	     
	  };
	 
	render () {
		const {loading,data,pagination} = this.state;
		let {fileList} = this.state;
		
		const columns = [
		{
			 
		  title: 'Id', 
		  dataIndex: 'id',
		},
		{
			
		  title: 'Device',
		  dataIndex: 'name', 
		  width: 150,
		  render:(text,row) => <span>{text+' - '+row.model}</span>
		},{
		  title: 'IMEI',
		  dataIndex: 'uniqueid',
		},
		{
		  title: 'Sim id',
		   dataIndex: 'sim_id',
		   key:'sim_id'
		},
		{
		  title: 'Sim Number',
		  dataIndex: 'sim_mobile',
		  key:'sim_mobile'
		},
	
		,{
		
		  title: 'Installed By',
		  dataIndex: 'installed_date',
		  key:'installed_date'
		}
		,{
			title: 'Date',
			dataIndex: 'created_at',
			render: text => moment(text).format("D MMM YYYY"),
		}
		/*,{
		  title: 'Status',
		  dataIndex: 'status',
		  render : status => (<Tag color={status == 'Active' ? '#87d068' : '#f50'} key={status}> {status}</Tag>)
		}*/
		];
		
		const uploadprops = {
			 
			beforeUpload: file => {
				fileList = [file]
				this.setState( {fileList},()=>{
					this.handleUpload();
				});
				return false;
			},
			showUploadList:false
		};

		return (
			<Card 
				title={`All Devices Inventory ( ${data.total} )`}
				extra={<><Upload {...uploadprops}><Button type="primary"icon={<UploadOutlined  />}>Upload Device CSV</Button></Upload><Link to="/main/devices/add"> <Button type="primary"icon={<PlusOutlined/>}>New Device</Button></Link></>}
			>
			<Row style={{marginBottom: 15}}>
				<Col xl={4} lg={4} md={4} sm={6} xs={24}>
					<Input placeholder="Search" value={this.setState.searchTerm} onChange={(e) => this.setState({searchTerm:e.target.value})}/>
				</Col>
				<Col xl={2} lg={2} md={2} sm={2} xs={24}>
					<Button type="primary" onClick={this.handleSearch} >Search</Button>
				</Col>
				<Col xl={2} lg={2} md={2} sm={2} xs={24}>
					<Button type="primary" onClick={this.handleReset}>Reset</Button>
				</Col>
			</Row>
		      <Table size={'small'} 
			      loading={loading} 
			      onChange={this.handleTableChange} 
			      className="gx-table-responsive" 
			      columns={columns} 
			      dataSource={data.data} 
			      pagination={pagination}
		      />
		    </Card>
		)
	}
	
}

export default AllDevices;
