import React, {Component} from "react";
import {Link} from "react-router-dom";
import fetch from "isomorphic-fetch";
import {Button, Form, Input} from "antd";
import { GoogleMap, InfoWindow, withGoogleMap, Marker, Circle} from "react-google-maps";
import axios from 'axiosConfig';
import Widget from "components/Widget/index";

const google = window.google;
let mySetTimeout;

const MyGoogleMapComponent = withGoogleMap(props => (
  <GoogleMap
    defaultZoom={15}
    center={props.center} 
  >
    {props.markers.map((marker, index) => (
      <Marker
        defaultIcon={ props.renderMarkerIcon( marker.device.attr_category ) }
        label= {marker.device.name}
        key={index}
        position={marker.position}
        onClick={() => props.onMarkerClick(marker)}
      >
        {marker.showInfo && (
          <InfoWindow onCloseClick={() => props.onMarkerClose(marker)}>
            <div>{marker.infoContent}</div>
          </InfoWindow>
        )}
      </Marker>
    ))}
  </GoogleMap>
));


export default class DevicesLiveTracking extends Component {
	
	constructor(){
		super();
		this.state = {
    		center   : { lat: 27.566698, lng: 76.626778 },
    		markers  : [],
    		loading  : true,
    		username : "",
    		numbers  : [1234, 22345, 32435, 42453, 52543],
    		users    : []
		};
		this.handleChange = this.handleChange.bind(this);
		this.locateDevice = this.locateDevice.bind(this);
		
	}
	
	async componentDidMount() {
		this.getDeviceLocation();
		try {
            setInterval(async () => {
              
              this.getDeviceLocation();
              
            }, 3000);
          } catch(e) {
            console.log(e);
          }
          
	}
	
	
	componentWillUnmount() {
		clearTimeout( mySetTimeout );
	}
	
	getDeviceLocation() {
    	axios.get('get-all-device-locations')
    		.then((response) => {
    			let alldata = response.data;
				let markers = [];
        
        		alldata.forEach((item) =>{
	        
				   markers.push( {
			        position: { lat: item.latitude, lng: item.longitude },
			        showInfo: false,
			        ignition: item.attributes.ignition,
			        device:item.device,
			        name:item.device.name,
			        infoContent: (
			          <div className="d-flex">
			            <div className="ml-1">
			              <p>Id: {item.deviceId} | Type : {item.device.attr_category} | Name : {item.device.name}</p>
			              <p>{item.address}</p>
			              <p>protocol : {item.protocol} | Lat : { item.latitude} , Lng : {item.longitude}</p>
			            </div>
			          </div>
			        ),
			      });
      
    			})
        
    			this.setState({markers:markers,loading:false})
        
		})
		.catch((error) => { console.log(error); })
	}
	
	handleMarkerClick = this.handleMarkerClick.bind(this);
	handleMarkerClose = this.handleMarkerClose.bind(this);
	renderIcon		  = this.renderIcon.bind(this);

	renderIcon(param) {
	  switch(param) {
	    case 'Bike'   : return require("assets/images/vehicles/bike.png");
	    case 'Truck'  : return require("assets/images/vehicles/truck.png");
	    case 'Tractor': return require("assets/images/vehicles/tractor.png");
	    case 'Car'    : return require("assets/images/vehicles/car.png");
	    default       : return require("assets/images/vehicles/bike.png");
	  }
	}
	
	// Toggle to 'true' to show InfoWindow and re-renders simple
	handleMarkerClick(targetMarker) {
	this.setState({
	  markers: this.state.markers.map(marker => {
	    if (marker === targetMarker) {
	      return {
	        ...marker,
	        showInfo: true,
	      };
	    }
	    return marker;
	  }),
	});
	}
	
	handleMarkerClose(targetMarker) {
	this.setState({
	  markers: this.state.markers.map(marker => {
	    if (marker === targetMarker) {
	      return {
	        ...marker,
	        showInfo: false,
	      };
	    }
	    return marker;
	  }),
	});
	}
	
	
	handleChange(event) {
		let filtereduser = [];
		const lowerCasedInput = event.target.value.toLowerCase();
		
		this.setState({ users: filtereduser });
		
		this.state.markers.map( (item,index) => { 
		  
			const lowerCased = item.name.toLowerCase();
			
			if ( lowerCased.includes( lowerCasedInput ) ){ filtereduser.push({item:item}); }
		   
		});
	  
		if(lowerCasedInput.length > 0 )
			this.setState({username: lowerCasedInput,users:filtereduser});
		else
			this.setState({username: "",users:[]});
	}

	locateDevice(event,item){
		// console.log(item.position);
		this.setState({center:item.position,username: "",users:[]});
		// console.log(event.target);
	}
  
  render() {

    return (
      <div>
      
    <Widget>
      <Form className="gx-signup-form gx-form-row0 gx-mb-0">
        <div className="gx-mb-3">
          <Input placeholder="Locate Device" value={this.state.username} onChange={this.handleChange} />
        </div>
      </Form>
      <div>
    	<ul style={{listStyle: "none"}}>
    		{ this.state.users.map((user,index) => 
    			<li key={index} style={{cursor: "pointer"}} value={user.item.name} onClick={(e) => {
      this.locateDevice(e,user.item);
   }}
   >{user.item.name} | Engine {user.item.ignition == true ? 'On' : 'Off'}</li>) 
    		}
		</ul>
      </div>
    </Widget>
        
        <MyGoogleMapComponent
          loadingElement={<div style={{height: `100%`}}/>}
          containerElement={<div style={{height: `550px`}}/>}
          mapElement={<div style={{height: `100%`}}/>}
          center={this.state.center}
          markers={this.state.markers}
          onMarkerClick={this.handleMarkerClick}
          onMarkerClose={this.handleMarkerClose}
          renderMarkerIcon={this.renderIcon}
        />
      </div>
    );
  }

}
