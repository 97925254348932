import React				from  "react";
import {Route, Switch}		from  "react-router-dom";
import Widgets				from  "./Widgets";
import Metrics				from  "./Metrics";
import Dashboard			from  "./dashboard";
import Layouts				from  "./Layouts";
import DevicesLiveTracking	from  "./Map/DevicesLiveTracking";
import DeviceDetails		from  "./Map/DeviceDetails";
import DeviceTrips			from  "./Map/DeviceTrips";
import DeviceSummary		from  "./Map/DeviceSummary";
import DevicesRoute 		from  "./Devices/route";
import SimRoute 			from  "./Sim/route";
import UserRoute			from  "./Users/route";
import ClientRoute			from  "./Clients/route";
import VehicleRoute			from  "./Vehicle/route";
import TrackingRoute		from  "./Tracking/route";
import PlanRoute			from  "./Plan/route";
import Dash					from  "./dash";
import asyncComponent		from  "../../util/asyncComponent";

const Main = ({match}) => (
  <Switch>
	<Route path={`${match.url}/users`}							component={UserRoute}/>
	<Route path={`${match.url}/clients`}						component={ClientRoute}/>
	<Route path={`${match.url}/devices`}						component={DevicesRoute}/>
	<Route path={`${match.url}/sim`}							component={SimRoute}/>
	<Route path={`${match.url}/vehicles`}						component={VehicleRoute}/>
	<Route path={`${match.url}/tracking`}						component={TrackingRoute}/>
	<Route path={`${match.url}/plan`}							component={PlanRoute}/>	
    <Route path={`${match.url}/dashboard`}						component={Dashboard}/>
    <Route path={`${match.url}/widgets`}						component={Widgets}/>
    <Route path={`${match.url}/metrics`}						component={Metrics}/>
    <Route path={`${match.url}/layouts`}						component={Layouts}/>
    <Route path={`${match.url}/algolia`}						component={asyncComponent(() => import('../algolia'))}/>
    <Route path={`${match.url}/`}								component={Dash}/>
  </Switch>
);

export default Main;
