import React, {Component} from "react";
import {Link} from "react-router-dom";
import fetch from "isomorphic-fetch";
import {Row,Col,Card,Tabs,Radio,Button,DatePicker} from "antd";
import { GoogleMap, LoadScript,Polyline,MarkerClusterer, Marker  } from '@react-google-maps/api';
import axios from 'axiosConfig';
import moment from 'moment';
import LoadMap from './LoadMap';

const google = window.google;
const TabPane = Tabs.TabPane;
const dateFormat = 'MMMM Do YYYY';

const mapContainerStyle = {
  height: "100%",
  width: "100%"
};

const options = {
  strokeColor: '#414949',
  strokeOpacity: 1.0,
  strokeWeight: 4,
  fillColor: '#414949',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1
};

const markeroptions = {
  imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
};


export default class DeviceSummary extends Component {
	
  isUnmounted :false;
  constructor() {
    super();
    this.state = {
		intervalId: null,
		imei:359632107788934,
		pathCoordinates: [],
		center:null,
		loading:true,
    };
  }

  componentDidMount() {
  	const { imei } = this.props.match.params;
   this.getDeviceLocation();
  }
  
  getDeviceLocation() {
  	const {imei} = this.state;
    axios.get('get-device-date-trip',{params:{imei}})
      .then((response) => {
        let alldata = response.data;
        
        let pathCoordinates = [];
        let markers = [];
        
        for(let i=0; i<alldata[0].length; i++)
        {
        	let item = alldata[0][i];
        	let gpsdata    = item.gps;
        	
        	 pathCoordinates.push({
	        	'lat' : gpsdata.latitude,
	        	'lng' : gpsdata.longitude,
	        })
        }
        
        let center = pathCoordinates[Math.floor(pathCoordinates.length/2)];
        markers.push( pathCoordinates[0] );
        markers.push( pathCoordinates[pathCoordinates.length-1] )
        this.setState({pathCoordinates,center,markers,loading:false})
        
      })
      .catch((error) => {
        console.log(error);
      })
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }
  
  handleTabOnChange = (tab) =>{
  	console.log(tab);
  	if(tab == 'Trips')
  	{
  		this.getDeviceLocation();
  	}
  }
  
  
  render() {
  	const {loading,pathCoordinates,center,markers,imei} = this.state;
  	
  	if( loading )
  		return null;
  		
    return (
    	
    		<Row style={{height:'100%',width:'100%',background:'#fff'}}>
    			<Col xl={8} md={8} sm={8} xs={24} style={{padding:0}}>
			    <div class="gx-chat-sidenav-header">
				   <div class="gx-chat-user-hd" style={{marginBottom:0}}>
				      <div class="gx-chat-avatar gx-mr-3">
				         <div class="gx-status-pos">
				            <span id="avatar-button" class="ant-avatar gx-size-50 ant-avatar-circle ant-avatar-image">
				            <img src="https://via.placeholder.com/150" alt=""/>
				            </span><span class="gx-status gx-online"></span>
				         </div>
				      </div>
				      <div class="gx-module-user-info gx-flex-column gx-justify-content-center">
				         <div class="gx-module-title">
				            <h5 class="gx-mb-0">1PY5405ECKA000604</h5>
				            <h6 class="gx-mb-0">JOHN DEERE 5405</h6>
				         </div>
				         <div class="gx-module-user-detail"><span class="gx-text-grey gx-link">Update time: 7/7/2020, 10:15 AM IST</span></div>
				      </div>
				   </div>
				</div>
				<Row style={{margin:0,borderBottom:'1px solid #eee'}}>
					<Col md={24} style={{margin:8}}>
						<Link to={`/main/live-tracking/${imei}`}><Button  type="link">Details</Button></Link>
						<Link to={`/main/live-tracking/${imei}/trips`}><Button type="link">Trips</Button></Link>
						<Link to={`/main/live-tracking/${imei}/summary`}><Button type="primary">Summary</Button></Link>	
					</Col>
				</Row>
	 
				 
				    
				</Col>
				<Col  xl={16} md={16} sm={16} xs={24} style={{padding:0}}>
			        Summary Will appear here
		      </Col>
    		</Row>
       
    );
  }
}
