import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Card, Checkbox, Form, Input, message,Select,Col} from "antd";
import {UnorderedListOutlined } from '@ant-design/icons';
import axios from 'axiosConfig';

const FormItem = Form.Item;
const { Option } = Select;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

class AddUser extends Component {
	
	 formRef = React.createRef();
	 
	constructor() {
	    super();
	    this.state = {
			loading:true,
			btnLoading:false,
			categories : [],
			makers: [],
			models: [],
			devices:[],
	    };
	  }
	  
	 componentDidMount() {
	 	 this.getCategories();
	 }
	 
	getCategories = () =>{
		axios.get('categories').then((response) =>{
			let resp		= response.data;
			let categories  = resp.data;
			this.setState({categories,loading:false})
		})
	}
	
	getMakers = (params = {}) =>{
		this.setState({loading:true})
		axios.get('brands?all=1',{params:params})
		.then((response) => {
			let resp = response.data;
			let makers = resp.data;
			
			this.setState({loading:false,makers})
		})
		.catch((error) => {
			console.log(error);
		})
	}
	
	getModels = (params = {}) =>{
		this.setState({loading:true})
		axios.get('models?all=1',{params:params})
		.then((response) => {
			let resp = response.data;
			let models = resp.data;
			
			this.setState({loading:false,models})
			this.getDevices();
		})
		.catch((error) => {
			console.log(error);
		})
	}
	
	getDevices = (params = {}) =>{
		this.setState({loading:true})
		axios.get('devices?all=1',{params:params})
		.then((response) => {
			let resp = response.data;
			let devices = resp.data;
			
			this.setState({loading:false,devices})
		})
		.catch((error) => {
			console.log(error);
		})
	}
	
	onSelectCategory = ( value ) => {
		let category_id = value;
		this.setState({category_id:value},()=>{
			this.getMakers({category_id});
		})
		
	}
	
	onSelectMaker = ( value ) => {
		const {category_id} = this.state;
		let maker_id = value;
		
		this.setState({maker_id:maker_id},()=>{
			this.getModels({category_id,maker_id});
		})
		
		
	}
	 
	 
	  onFinish = (values) => {
	  	this.setState({btnLoading:true});
		axios.post('customers',values)
		.then((response) => {
			let data = response.data;
			if(data.success)
			{
				message.success(data.message);
				this.formRef.current.resetFields();
			}
			else
				message.error(data.message);
			
			this.setState({btnLoading:false});
	  })
	  .catch((error) => {
		let resp = error.response
	  	if(resp.status == 422)
	  	{
	  		let data = resp.data;
	  		
	  		Object.keys(data).map((item)=>{
	  			 message.error(data[item][0]);
	  		})
	  	}
	  	else
	    	message.error('Something went wrong! Please try again');
	    	
	    this.setState({btnLoading:false});
	  })
	 
	 }
	 
	 
	 onReset = () => {
		    this.formRef.current.resetFields();
	 };
	 
	 render() {
	 	const {loading,btnLoading,categories,makers,models,devices} = this.state;
	 		
	    return (
	    	<Card className="gx-card" title="Add New Client" extra={<Link to="/main/clients"> <Button type="primary" icon={<UnorderedListOutlined/>}>All Users</Button></Link>}>
	    		
	    		<Col xs={24} xl={12} md={12} lg={12} sm={24}>
	    		<Form
					{...layout}
					name="add_device"
					ref={this.formRef} 
					colon={false}
					onFinish={this.onFinish}
				>
			          
			          <FormItem
			        	label="Name"
	        			rules={[{ required: true, message: 'Please Enter Full Name!' }]}
	                    name="name"
			           >
			            <Input placeholder="Enter Full Name"/>
			          </FormItem>
			          
			          <FormItem
			        	label="Mobile"
	        			rules={[{ required: true, message: 'Please Enter Primary Contact NumberI!' }]}
	                    name="mobile"
	                    extra="i.e: 919999999999"
			           >
			            <Input min={10} max={10} placeholder="Enter Primary Contact Number"/>
			          </FormItem>
			          
			          <FormItem
			        	label="Email"
	        		//	rules={[{ required: true, message: 'Please Email address!' }]}
	                    name="email"
	                    extra="example@email.com"
			           >
			            <Input placeholder="Enter Email address"/>
			          </FormItem>
			          
			           <FormItem
			        	label="Password"
	        			rules={[{ required: true,message: 'Password missing!' }]}
	                    name="password"
			           >
			            <Input min={6} placeholder="Enter Secure password"/>
			          </FormItem>
			          
			          <Form.Item name="category_id" label="Vehicle Type" rules={[{ required: true }]}>
				        <Select
				         name="category_id"
				          placeholder="Select Vehicle Type"
				          onChange={this.onSelectCategory}
				          filterOption={(input, option) => option.props.datavalue.toLowerCase().indexOf(input.toLowerCase()) >= 0}
				          allowClear
				          showSearch
				        >
					        {
					        	categories.map((category,index) =>{
					        		return <Option key={category.id} datavalue={category.title} value={category.id}>{category.title.toUpperCase()}</Option>
					        	})
					        }
				        </Select>
				      </Form.Item>
				      
				      <Form.Item name="make_id" label="Make" rules={[{ required: true }]}>
				        <Select
				          placeholder="Select Vehicle Makers"
				          onChange={this.onSelectMaker}
				          filterOption={(input, option) => option.props.datavalue.toLowerCase().indexOf(input.toLowerCase()) >= 0}
				          allowClear
				          showSearch
				        >
				        {
				        	makers.map((maker,index) =>{
				        		return <Option key={maker.id} datavalue={maker.title} value={maker.id}>{maker.title.toUpperCase()}</Option>
				        	})
				        }
							 
				        </Select>
				      </Form.Item>
				      
				      <Form.Item name="model_id" label="Model" rules={[{ required: true }]}>
				        <Select
				          placeholder="Select Vehicle Models"
				          filterOption={(input, option) => option.props.datavalue.toLowerCase().indexOf(input.toLowerCase()) >= 0}
				          allowClear
				          showSearch
				        >
				        {
				        	models.map((model,index) =>{
				        		return <Option key={model.id} datavalue={model.title} value={model.id}>{model.title.toUpperCase()}</Option>
				        	})
				        }
							 
				        </Select>
				      </Form.Item>
				      
				      <FormItem
			        	label="Registation No"
	        			rules={[{ required: true,message: 'Vehicle Registration missing!' }]}
	                    name="reg_no"
			           >
			            <Input placeholder="Enter Vehicle Registartion No"/>
			          </FormItem>
			          
			           <Form.Item name="device_id" label="Device" 
			        		rules={[{ required: true,message: 'GPS Device id missing!' }]}
			        	>
				        <Select
						filterOption={(input, option) => option.props.datavalue.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						placeholder="Select Vehicle Models"
						allowClear
						showSearch
				        >
				        {
				        	devices.map((device,index) =>{
				        		return <Option key={device.id} datavalue={device.uniqueid} value={device.id}>{device.uniqueid}</Option>
				        	})
				        }
							 
				        </Select>
				      </Form.Item>
			          
			          
			          <FormItem label="&nbsp;">
			            <Button type="primary" htmlType="submit" loading={btnLoading}>
			              Save
			            </Button>
			             <Button htmlType="button" onClick={this.onReset} >
				            Reset
				          </Button>
			          </FormItem>
			        </Form>
			        </Col>
	    	</Card>
	    );
  }
}


export default (AddUser);
