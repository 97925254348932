import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Card, Checkbox, Form, Input, message,Select,Col} from "antd";
import {UnorderedListOutlined } from '@ant-design/icons';
import axios from 'axiosConfig';

const FormItem = Form.Item;
const { Option } = Select;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

class AddMake extends Component {
	
	 formRef = React.createRef();
	 
	constructor() {
	    super();
	    this.state = {
			loading:true,
			btnLoading:false,
			categories : [],
	    };
	  }
	  
	 componentDidMount() {
	 	 this.getCategories();
	 }
	 
	 getCategories = () =>{
		axios.get('list-categories').then((response) =>{
			let data = response.data;
			this.setState({categories:data.data,loading:false})
		})
	}
	 
	 
	  onFinish = (values) => {
	  	this.setState({btnLoading:true});
		axios.post('brands',values)
		.then((response) => {
			let data = response.data;
			if(data.success)
			{
				message.success(data.message);
				this.formRef.current.resetFields();
			}
			else
				message.error(data.message);
			
			this.setState({btnLoading:false});
	  })
	  .catch((error) => {
	    message.error('Something went wrong! Please try again');
	    this.setState({btnLoading:false});
	  })
	 
	 }
	 
	 
	 onReset = () => {
		    this.formRef.current.resetFields();
	 };
	 
	 render() {
	 	const {loading,btnLoading,categories} = this.state;
	 		
	    return (
	    	<Card className="gx-card" title="Add New Make/Brand" extra={<Link to="/main/vehicles/make"> <Button type="primary" icon={<UnorderedListOutlined/>}>All Brands</Button></Link>}>
	    		<Col xs={24} xl={12} md={12} lg={12} sm={24}>
	    		<Form
					{...layout}
					colon={false}
					name="add_make"
					ref={this.formRef} 
					initialValues={{ remember: true }}
					onFinish={this.onFinish}
				>
					
			          <Form.Item name="category_id" label="Category" rules={[{ required: true }]}>
				        <Select
				          placeholder="Select Vehicle Type"
				          allowClear
				        >
					        {
					        	categories.map((category,index) =>{
					        		return <Option key={category.id} value={category.id}>{category.title.toUpperCase()}</Option>
					        	})
					        }
				        </Select>
				      </Form.Item>
				      
			          <FormItem
			        	label="Make/Brand"
	        			rules={[{ required: true, message: 'Please Enter Brand Name!' }]}
	                    name="title"
			           >
			            <Input placeholder="Enter Brand Name"/>
			          </FormItem>
			          <FormItem label="&nbsp;">
			            <Button type="primary" htmlType="submit" className="login-form-button gx-mt-1" loading={btnLoading}>
			              Save
			            </Button>
			          </FormItem>
			        </Form>
			   </Col>
	    	</Card>
	    );
  }
}


export default (AddMake);
