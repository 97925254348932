import React, {Component} from "react";
import {Link} from "react-router-dom";
import fetch from "isomorphic-fetch";
import { GoogleMap, LoadScript,InfoWindow,MarkerClusterer, Marker} from '@react-google-maps/api';
import axios from 'axiosConfig';
import LoadMap from './LoadMap';


const google = window.google;

const mapContainerStyle = {
  height: "100%",
  width: "100%"
};

const options = {
  imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
};

function createKey(location) {
  return location.lat + location.lng
};



export default class DevicesLiveTracking extends Component {
	
  isUnmounted :false;
  
  constructor() {
    super();
    this.state = {
		intervalId: null,
		markers: [],
		loading:true,
		AllMarkers:[]
    };
  }

  componentDidMount() {
   this.getDeviceLocation();
    this.state.intervalId = setInterval(()=>{
      this.getDeviceLocation();
    }, 30000);
  }
  
  getDeviceLocation() {
    axios.get('get-all-device-locations')
      .then((response) => {
        let alldata = response.data;
        
        let markers = [];
        
        
        alldata.forEach((item) =>{
        	if(!item.hasOwnProperty('data'))
        		return;
        	
	        let gpsdata    = item.data.gps;
	        let ioElements = item.data.ioElements;
	        let imei       = item.imei; 
	        let ignition = 'N/A';
	        
	        ioElements.forEach((ioitem) => {
	        	if(ioitem.id == 239)
	        	{
	        		ignition = ioitem.value;
	        	}
	        })
	        
	        markers.push( {
		        position: {lat:gpsdata.latitude, lng:gpsdata.longitude},
		        latlngSelf : {lat:gpsdata.latitude,lng:gpsdata.longitude},
		        showInfo: false, 
		        ignitionStatus:ignition,
		        infoContent: (
		          <div className="d-flex">
		            <div className="ml-1 gx-font-weight-medium">
		              <p>IGNITION {ignition == 0 ? <span className="gx-chart-down">OFF</span> : <span className="gx-chart-up">ON</span> }</p>
		              <p>IMEI <span>{imei}</span></p>
		            </div>
					<Link to={`/main/live-tracking/`+imei} className="gx-btn gx-btn-sm gx-btn-primary gx-text-white gx-mb-1">Details</Link>
		          </div>
		        )
		      })
        })
        
        this.setState({markers,loading:false})
        
      })
      .catch((error) => {
        console.log(error);
      })
  }

  componentWillUnmount() {
    this.isUnmounted = true;
    clearInterval(this.state.intervalId)
  }
  
  handleMarkerClick = this.handleMarkerClick.bind(this);
  handleMarkerClose = this.handleMarkerClose.bind(this);

  handleMarkerClick(targetMarker) {
	var geocoder = new window.google.maps.Geocoder;
	
	geocoder.geocode({'location': targetMarker.latlngSelf}, (results, status) =>  {
      if (status === 'OK') {
        if (results[0]) {
        	
          this.setState({
		      markers: this.state.markers.map(marker => {
		        if (marker === targetMarker) {
		          return {
		            ...marker,
		            showInfo: true,
		          };
		        }
		        return marker;
		      }),
		    });
           
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

	handleMarkerClose(targetMarker) {
    this.setState({
      markers: this.state.markers.map(marker => {
        if (marker === targetMarker) {
          return {
            ...marker,
            showInfo: false,
          };
        }
        return marker;
      }),
    });
  }

  render() {
  	const { markers,loading } = this.state;
  	if( loading )
  		return null;
  		
    return (
      <LoadMap>
		  <GoogleMap 
				id='marker-cluster' 
				zoom={3}
				mapContainerStyle={mapContainerStyle} 
				onLoad={map => {
				    const bounds = new window.google.maps.LatLngBounds();
				    for(let i=0 ; i < markers.length ; i++ )
				    	bounds.extend(markers[i].latlngSelf);

				    map.fitBounds(bounds);
				  }}
			>
			    <MarkerClusterer options={options}>
			      {clusterer =>
			        markers.map((marker,index) => (
			          <Marker icon={require(`assets/images/${marker.ignitionStatus == 1 ? 'marker-green.png' :'marker-red.png'}`)}  key={index} onClick={()=>this.handleMarkerClick(marker)} position={marker.latlngSelf} clusterer={clusterer}>
			          {marker.showInfo && (
				          <InfoWindow>
				            <div>{marker.infoContent}</div>
				          </InfoWindow>
				        )}
			          </Marker>
			        ))
			      }
			    </MarkerClusterer>
		  </GoogleMap>
		</LoadMap>
    );
  }
}
