import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Card,Tag,Table,Button,Row,Col,Input,message,Upload} from "antd";
import moment from 'moment';
import axios from 'axiosConfig';
import {PlusOutlined , UploadOutlined ,ScheduleOutlined }  from '@ant-design/icons';

class History extends Component {
	
	constructor() {
	    super();
	    this.state = { 
		 loading:true,
		 data:[],
		 fileList: [],
		 pagination: {
				defaultPageSize: 10,
				showSizeChanger: false,
				pageSizeOptions: ["10", "20", "30"],
			}
	    };
	  }
	  
	 componentDidMount() {
	 	 this.getData();
	 }
	 
	handleTableChange = (pagination, filters, sorter) => {
		const pager = { ...this.state.pagination };
		pager.current = pagination.current;
		this.getData(pagination,filters,sorter)
		 
	};
	
	getData = (params = {}) => {
		
		this.setState({loading:true});
		
		const {pagination} =  this.state ;
		axios.get('recharge-history',{params:params})
		.then((response) => {
			
			let data = response.data;
		 
			pagination.total = data.total;
			pagination.current = data.current_page;
			
			this.setState({loading:false,data,pagination})
			
		})
		.catch((error) => {
			console.log(error);
		})
	}
	
	
	planDays = (start_date, end_date) => {
		const date1 = new Date(start_date);
		const date2 = new Date(end_date);
		const diffTime = Math.abs(date2 - date1);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
		return diffDays;
	};
	 
	render () {
		const {loading,data,pagination} = this.state;
		let {fileList} = this.state;
		
		const columns = [
			{
				title: 'Id', 
				dataIndex: 'id',
			},
			{
				title:'DeviceId',
				dataIndex:'device',
				render : device => device.uniqueid
			},
			{
				title:'Client',
				dataIndex:'device',
				render: device => device.name ,
			},{
				title:'Contact',
				dataIndex:'device',
				render: device => device.contact ,
			},{
			  title: 'Plan Amount',
			  dataIndex: 'amount', 
			  width: 150,
			},{
				title: 'Recharge Id',
				dataIndex:'recharge_id',
				width:150,
			},{
			  title: 'Validity',
			  dataIndex: 'plan_price',
			  render: ( text, obj ) => this.planDays( obj.start_date, obj.valid_upto )+ ' Days',
			},{
			  title: 'Created',
			  dataIndex: 'created_at',
			  render: text => moment(text).format("D MMM YYYY"),
			},{
				title: 'Updated',
				dataIndex: 'updated_at',
				render: text => moment(text).format("D MMM YYYY"),
			}
		];

		return (
			<Card title={`All Recharges ( ${data.total} )`} >
		      <Table size={'small'} 
			      loading={loading} 
			      onChange={this.handleTableChange} 
			      className="gx-table-responsive" 
			      columns={columns} 
			      dataSource={data.data} 
			      pagination={pagination}
		      />
		    </Card>
		)
	}
	
}

export default History;
